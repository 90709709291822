import Header from './header';
import Footer from './footer';

function App() {
  return (
    <div class="page-wrapper">
    <h1 class="d-none">Jampani Pickles</h1>
  
    <Header />  

    <main class="main">
            <nav class="breadcrumb-nav">
                <div class="container">
                    <ul class="breadcrumb bb-no">
                        <li><a href="/">Home</a></li>
                        <li>Error 404</li>
                    </ul>
                </div>
            </nav>
           
            <div class="page-content error-404">
                <div class="container">
                    <div class="banner">
                        <div class="banner-content text-center" style={{minHeight:'50vh', marginTop:'20vh'}}>
                            <h2 class="banner-title">
                                <span class="text-secondary">Oops!!!</span> Something Went Wrong Here
                            </h2>
                            <p class="text-light">There may be a misspelling in the URL entered, or the page you are looking for may no longer exist</p>
                            <a href="/" class="btn btn-dark btn-rounded btn-icon-right">Go Back Home<i class="w-icon-long-arrow-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </main>

        <Footer />
    
    
</div>
  );
}

export default App;
