import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ReactSession } from 'react-client-session';
import { useNavigate, useParams } from 'react-router-dom';
import Header from './header';
import Footer from './footer';
import Razorpay from './razorpay';
ReactSession.setStoreType("localStorage");

function App() {

    const [totalWeight, setweight] = useState(0);
    const [selectedsCountry, setSelectedsCountry] = useState('ind');
    const [selectedbCountry, setSelectedbCountry] = useState('ind');
    const [cartItems, setCartItems] = useState([]);
    const [error, setError] = useState('');
    const [shippingRate, setShipping] = useState(0);
    const [totalRate, setTotal] = useState(0);
    const [couponDisc, setCouponDisc] = useState(0);
    const [gstRate, setGst] = useState(0);
    const [coupon, setCoupon] = useState('');
    const [isShip, setIsShip] = useState(false);
    const [isButton, setIsButton] = useState(false);

    const [orderId, setOrderId] = useState('');
    const [paymentId, setPaymentId] = useState('');
    const [grandTotal, setGrandTotal] = useState('');

    const userId = sessionStorage.getItem('jmpiUserId');
    const [userData, setUserData] = useState('');
    const [shippingData, setShippingData] = useState('');
    const [shipAdrs, setShippingAdrs] = useState('');
    const [billAdrs, setBillingAdrs] = useState('');

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');


    const getUserData = async () => {
        try{
            const response = await axios.get('https://demo.sninfoserv.com/api/customer/login/', {params:{userId: userId}});
            console.log(response.data);
            if(response.data.status === 'success'){
                setUserData(response.data.userData);
                setShippingData(response.data.shippingAddress);
            }else{
                setError(response.data.message);
            }
         }catch(error){
            setError({ error: error.message });
         }
    };

    useEffect(()=>{
        getUserData();
    },[userId]);


    const navigate = useNavigate();

    const CountryName = (selectedCountry) => {
    
        let country;
        switch (selectedCountry) {
          case 'usa':
            country = 'United States';
            break;
          case 'uk':
            country = 'United Kingdom';
            break;
          case 'fra':
            country = 'France';
            break;
          case 'aus':
            country = 'Australia';
            break;
          case 'can':
            country = 'Canada';
            break;
          case 'nz':
            country = 'New Zealand';
            break;
          case 'ger':
            country = 'Germany';
            break;
          case 'net':
            country = 'Netherlands';
            break;
          case 'den':
            country = 'Denmark';
            break;
          case 'ita':
            country = 'Italy';
            break;
          case 'ire':
            country = 'Ireland';
            break;
          case 'bel':
            country = 'Belgium';
            break;
          case 'por':
            country = 'Portugal';
            break;
          case 'swe':
            country = 'Sweden';
            break;
          case 'sin':
            country = 'Singapore';
            break;
          case 'dub':
            country = 'Dubai';
            break;
          case 'mal':
            country = 'Malaysia';
            break;
          case 'ind':
            country = 'India';
            break;
          default:
            country = 'Select a Country';
            
        }

        return country;
        
    };

    const CountryNameRev = (selectedCountry) => {
    
        let country;
        switch (selectedCountry) {
          case 'United States':
            country = 'usa';
            break;
          case 'United Kingdom':
            country = 'uk';
            break;
          case 'France':
            country = 'fra';
            break;
          case 'Australia':
            country = 'aus';
            break;
          case 'Canada':
            country = 'can';
            break;
          case 'New Zealand':
            country = 'nz';
            break;
          case 'Germany':
            country = 'ger';
            break;
          case 'Netherlands':
            country = 'net';
            break;
          case 'Denmark':
            country = 'den';
            break;
          case 'Italy':
            country = 'ita';
            break;
          case 'Ireland':
            country = 'ire';
            break;
          case 'Belgium':
            country = 'bel';
            break;
          case 'Portugal':
            country = 'por';
            break;
          case 'Sweden':
            country = 'swe';
            break;
          case 'Singapore':
            country = 'sin';
            break;
          case 'Dubai':
            country = 'dub';
            break;
          case 'Malaysia':
            country = 'mal';
            break;
          case 'India':
            country = 'ind';
            break;
          default:
            country = 'Select a Country';
            
        }

        return country;
        
    };

    const [BformData, setFormData] = useState({ bFirstname: '', bLastname: '', bAdrs1:'', bAdrs2: '', bTown:'', bZip:'', bState:'', bEmail: '', bPhone: '', bContry: '', shipAddress: false, orderNotes: ''});
    const [SformData, setsFormData] = useState({ sFirstname: '', sLastname: '', sAdrs1:'', sAdrs2: '', sTown:'', sZip:'', sState:'', sEmail: '', sPhone: '', sContry: ''});
    
    useEffect(() => {
        setFormData({
            ...BformData,
            ['bContry']: CountryName(selectedbCountry)
        });

        setsFormData({
            ...SformData,
            ['sContry']: CountryName(selectedsCountry)
        });
    },[selectedbCountry, selectedsCountry]);

    const carts =() =>{
        const storedCartItems = JSON.parse(sessionStorage.getItem('cartItems')) || [];
        setCartItems(storedCartItems);
    };
    
    useEffect(() => {
        carts();
    }, [cartItems]);
    

    useEffect(() =>{

        let tWeight = 0;
        let tRate = 0;
        let tGst = 0;
        cartItems && cartItems.forEach(item =>{
            tWeight += (item.productWeight * item.productQty);
            tRate += (item.productPrice * item.productQty);
            tGst += ((item.productPrice - (item.productPrice / (1 + (item.productGst / 100))))*item.productQty);
        });

        setweight(Number(tWeight/1000).toFixed(2));
        setTotal(Number(tRate).toFixed(2));
        setGst(Number(tGst).toFixed(2));
        setGrandTotal(Number(totalRate) + Number(shippingRate) - Number(couponDisc).toFixed(2));
    },[cartItems]);

    

    const shipping = async () =>{
        let selectedCountry;
        if(isShip === false){
            selectedCountry = selectedbCountry;
        }else{
            selectedCountry = selectedsCountry;
        }
        const shippingData = { weight: totalWeight, country: selectedCountry}
        try{
            const response = await axios.post('https://demo.sninfoserv.com/api/shipping/', shippingData);
            setShipping(response.data.data.shipping);
        }catch (error) {
            setError({ error: error.message });
        }
    }

    useEffect(() => {
        shipping();
    },[shippingRate, totalWeight, selectedsCountry, selectedbCountry, isShip]);
    

    


    const couponCode = (e) => {
      const input = e.target.value;
      setCoupon(input);
    };

    const applyCoupon = () => {
        const diccount = 10;
        setCouponDisc(diccount);
    };

    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({
        ...BformData,
        [name]: value
      });
    };

    const handleShipChange = (e) => {
        const { name, value } = e.target;
        setsFormData({
          ...SformData,
          [name]: value
        });
      };

    const handleShipAddress = () =>{
        if(isShip === false){
            setIsShip(true);
        }else{
            setIsShip(false);
        }
    };


    const sendOrderData = async (event) =>{
        setIsButton(true);
        event.preventDefault();
        const sendData = {
          billingData: BformData,
          shippingData: SformData,
          orderData: cartItems,
          shipping: isShip,
          pricing: {
            subTotal: totalRate,
            gst: gstRate,
            discount: couponDisc,
            shippingCharge: shippingRate,
            grandTotal: Number(totalRate) + Number(shippingRate) - Number(couponDisc)
          }
        };

        try{
            const response = await axios.post('https://demo.sninfoserv.com/api/orders/', sendData);
            //console.log(response.data);
            if(response.data.status === 'success'){
                setOrderId(response.data.orderId);
                setPaymentId(response.data.paymentId);
                
            }
        }catch (error) {
            setError({ error: error.message });
        }

    };

    const triggerPayment = () => {
        const paymentButton = document.getElementById('rzp-button1');
        if (paymentButton) {
          paymentButton.click();
        }
    };
    

    useEffect(()=>{
        if(shippingData.length > 0){
            setShippingAdrs(shippingData[0].adrsId);
            setBillingAdrs(userData.adrsId);
        }

    },[shippingData, userData]);

    useEffect(() => {
        const address = shippingData && shippingData.filter(adrs => adrs.adrsId === shipAdrs);
        
        if (address.length > 0) {
            setSelectedbCountry(CountryNameRev(address[0].country));
        } else {
            setSelectedbCountry(null);
        }
    }, [shipAdrs, shippingData]);

    const openLogin = () => {
        const loginSec = document.getElementById('loginSec');
        if (loginSec.classList.contains('open')) {
            loginSec.classList.remove('open');
            loginSec.style.display = 'none';
        } else {
            loginSec.classList.add('open');
            loginSec.style.display = 'block';
        }
    };
    

    const login = async (e) => {
        e.preventDefault();
   
        const loginData = {
           email: email,
           pass : password
        }
        try{
           const response = await axios.post('https://demo.sninfoserv.com/api/customer/login/', loginData);
           console.log(response.data);
           if(response.data.status === 'success'){
               sessionStorage.setItem('jmpiUserId', response.data.userId);
               getUserData();
           }else{
               setError(response.data.message);
           }
   
        }catch(error){
           setError({ error: error.message });
        }
   
    };

    const sendOrderDataCustomer = async (event) =>{
        event.preventDefault();
        const sendData = {
          billingData: billAdrs,
          shippingData: shipAdrs,
          orderData: cartItems,
          userId : userId,
          pricing: {
            subTotal: totalRate,
            gst: gstRate,
            discount: couponDisc,
            shippingCharge: shippingRate,
            grandTotal: Number(totalRate) + Number(shippingRate) - Number(couponDisc)
          }
        };

        try{
            const response = await axios.post('https://demo.sninfoserv.com/api/orders/customer/', sendData);
            //console.log(response.data);
            if(response.data.status === 'success'){
                setOrderId(response.data.orderId);
                setPaymentId(response.data.paymentId);
                
            }
        }catch (error) {
            setError({ error: error.message });
        }

    };


    return (
        <div class="page-wrapper">
          <h1 class="d-none">Jampani Pickles</h1>
          <Header />  
          <main class="main checkout">
            <nav class="breadcrumb-nav">
                <div class="container">
                    <ul class="breadcrumb shop-breadcrumb bb-no">
                        <li class="passed"><a href="/cart">Shopping Cart</a></li>
                        <li class="active"><a href="/checkout">Checkout</a></li>
                        <li><a>Order Complete</a></li>
                    </ul>
                </div>
            </nav>
        
            <div class="page-content">
             {cartItems.length > 0 ?
                <div class="container">

                   {userId > 0 ? (
                    <>
                     <div class="row">
                       <div class="col-lg-7 pr-lg-4 mb-4">
                        {userData &&
                        <>
                          <h5>Billing Address</h5>
                          <p>
                            <b>{userData.fName} {userData.lName}</b> <br />
                            {userData.adrs1}, {userData.adrs2} <br />
                            {userData.town}, {userData.state}, {userData.country} - {userData.zip}
                          </p>
                          <p>
                            {userData.mobile}<br />
                            {userData.email}
                          </p>
                        </>
                        }
                        <div class="row">
                        <h5>Select Shipping Address</h5>
                        {shippingData && shippingData.map((address, index)=>
                          <div class="col-lg-4">
                            <div style={{border: 'solid 1px #333', borderRadius: '15px', padding: '10px'}}>
                            <p>
                              <b>{address.fName}</b><br />
                              {address.adrs1}, {address.adrs2} <br />
                              {address.town}, {address.state}, {address.city} <br />
                              {address.country} - {address.zip}
                            </p>
                            <p>
                              {address.phone} <br />
                              {address.email}
                            </p>
                            <button class={`btn ${shipAdrs === address.adrsId ? 'btn-primary': 'btn-small-primary'} `} onClick={(e)=>setShippingAdrs(address.adrsId)}>{shipAdrs === address.adrsId ? 'Selected ' : 'Select'}</button>
                            </div>
                          </div>
                        )}  
                        </div>
                        



                       </div>
                       <div class="col-lg-5 mb-4 sticky-sidebar-wrapper">
                                <div class="order-summary-wrapper sticky-sidebar">
                                    <h3 class="title text-uppercase ls-10">Your Order</h3>
                                    <div class="order-summary">
                                        <table class="order-table">
                                            <thead>
                                                <tr>
                                                    <th style={{textAlign: 'left'}}> <b>Product</b> </th>
                                                    <th style={{textAlign: 'left'}}> <b>Price</b> </th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                            {cartItems.map((item, index) => (
                                                <tr class="bb-no">
                                                    <td class="product-name">{item.productName} {item.productWeight}g <i
                                                            class="fas fa-times"></i> <span
                                                            class="product-quantity">{item.productQty}</span></td>
                                                    <td class="product-total">₹ {Number(item.productPrice * item.productQty).toFixed(2)}</td>
                                                </tr>
                                            ))}

                                                <hr class="divider" />

                                                <tr class="cart-subtotal bb-no">
                                                    <td><b>Sub Total</b></td>
                                                    <td><b>₹ {Number(totalRate-gstRate).toFixed(2)}</b></td>
                                                </tr>

                                                <tr class="cart-subtotal bb-no">
                                                    <td>Shipping</td>
                                                    <td><b>₹ {Number(shippingRate).toFixed(2)}</b></td>
                                                </tr>

                                                <tr class="cart-subtotal bb-no">
                                                    <td>Discount</td>
                                                    <td><b class='text-danger'>{couponDisc > 0 ? '-' : ''} ₹ {Number(couponDisc).toFixed(2)}</b></td>
                                                </tr>

                                                <tr class="cart-subtotal bb-no">
                                                    <td>Tax</td>
                                                    <td><b>₹ {Number(gstRate).toFixed(2)}</b></td>
                                                </tr>
                                                <tr class="cart-subtotal bb-no">
                                                    <td><b>Grand Total</b></td>
                                                    <td><b class='text-success'>₹ {Number(grandTotal).toFixed(2)}</b></td>
                                                </tr>


                                            </tbody>
                                            
                                        </table>

                                        <div class="payment-methods mt-10" id="payment_method" >
                                            <h4 class="title font-weight-bold ls-25 pb-0 mb-1">Payment Methods</h4>
                                            <div class="accordion payment-accordion">
                                                <div class="card">
                                                    <div class="card-header">
                                                        <a href="#cash-on-delivery" class="collapse">Direct Bank Transfor, UPI Payments , Debit/Credit Cards</a>
                                                    </div>
                                                    <div id="cash-on-delivery" class="card-body expanded">
                                                        <p class="mb-0">
                                                            Make your payment directly into our bank account. 
                                                            Please use your Order ID as the payment reference. 
                                                            Your order will not be shipped until the funds have cleared in our account.
                                                            <br />
                                                            <b class='text-danger'>IMP: Please do not refresh or press back button until you'll recieve a confirmation message about your order from Jampani Pickles.</b>
                                                        </p>
                                                    </div>
                                                </div>
                                               
                                               
                                            </div>
                                        </div>

                                        <div class="form-group place-order pt-6">
                                            {paymentId.length === 0 ? isButton === false ?
                                              <button type="submit" class="btn btn-dark btn-block btn-rounded" onClick={sendOrderDataCustomer}>Confirm & Continue</button>
                                              : <p class="btn btn-dark btn-block btn-rounded">Creating Order please wait...</p>
                                              :
                                              <a onClick={triggerPayment} class="btn btn-primary btn-block btn-rounded">Pay Now</a>
                                            }
                                            
                                            <Razorpay
                                              Amount = {grandTotal} 
                                              razId = {paymentId} 
                                              orderId = {orderId}   
                                              Name = {userData.fName}
                                              Email = {userData.email}
                                              Phone ={userData.mobile}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                     </div>
                   
                    </>
                    ):(
                    <>
                    <div class="login-toggle" onClick={openLogin}>
                        Returning customer ? 
                        <a onClick={openLogin} class="show-login font-weight-bold text-uppercase text-dark"> Login</a>
                    </div>
                    <div class="login-content" style={{display: 'none'}} id="loginSec">
                      <form onSubmit={login}> 
                        <p>If you have shopped with us before, please enter your details below. 
                            If you are a new customer, please proceed to the Billing section.</p>
                        <div class="row">
                            <div class="col-xs-4">
                                <div class="form-group">
                                    <label>Email ID *</label>
                                    <input type="text" class="form-control form-control-md" value={email} onChange={(e)=>setEmail(e.target.value)} required />
                                </div>
                            </div>
                            <div class="col-xs-4">
                                <div class="form-group">
                                    <label>Password *</label>
                                    <input type="password" class="form-control form-control-md" value={password} onChange={(e)=> setPassword(e.target.value)} required />
                                </div>
                            </div>
                          
                        </div>
                        <div class="form-group checkbox">
                            <input type="checkbox" class="custom-checkbox" id="remember" name="remember" />
                            <label for="remember" class="mb-0 lh-2">Remember me</label>
                            <a href="/login" class="ml-3">Lost your password?</a>
                        </div>
                        <button type="submit" class="btn btn-rounded btn-login">Login</button>
                      </form>
                    </div>
                    <div class="coupon-toggle">
                        Have a coupon? <a href="#"
                            class="show-coupon font-weight-bold text-uppercase text-dark">Enter your
                            code</a>
                    </div>
                    <div class="coupon-content mb-4" style={{display: 'none'}}>
                        <p>If you have a coupon code, please apply it below.</p>
                        <div class="input-wrapper-inline">
                            <input type="text" name="coupon_code" class="form-control form-control-md mr-1 mb-2" placeholder="Coupon code" id="coupon_code" value={coupon} required onChange={couponCode} />
                            <button type="submit" class="btn button btn-rounded btn-coupon mb-2" name="apply_coupon" value="Apply coupon" onClick={applyCoupon}>Apply Coupon</button>
                        </div>
                    </div>


                    <form onSubmit={sendOrderData}>
                        <div class="row mb-9">
                            <div class="col-lg-7 pr-lg-4 mb-4">
                                <h3 class="title billing-title text-uppercase ls-10 pt-1 pb-3 mb-2 mt-5"> Billing Details </h3>
                                <div class="row gutter-sm">
                                    <div class="col-xs-6">
                                        <div class="form-group">
                                            <label>First name *</label>
                                            <input type="text" class="form-control form-control-md" name="bFirstname"
                                               value={BformData.bFirstname} required onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div class="col-xs-6">
                                        <div class="form-group">
                                            <label>Last name *</label>
                                            <input type="text" class="form-control form-control-md" name="bLastname"
                                              value={BformData.bLastname}  required onChange={handleChange} />
                                        </div>
                                    </div>
                                </div>
                               
                               
                                <div class="form-group">
                                    <label>Street address *</label>
                                    <input type="text" placeholder="House number and street name"
                                        class="form-control form-control-md mb-2" name="bAdrs1" required value={BformData.bAdrs1} onChange={handleChange} />
                                    <input type="text" placeholder="Apartment, suite, unit, etc. (optional)"
                                        class="form-control form-control-md" name="bAdrs2" required value={BformData.bAdrs2} onChange={handleChange} />
                                </div>
                                <div class="row gutter-sm">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label>Town / City *</label>
                                            <input type="text" class="form-control form-control-md" name="bTown" required value={BformData.bTown} onChange={handleChange} />
                                        </div>
                                        <div class="form-group">
                                            <label>ZIP *</label>
                                            <input type="text" class="form-control form-control-md" name="bZip" required value={BformData.bZip} onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label>State *</label>
                                            <input type="text" class="form-control form-control-md" name="bState" required value={BformData.bState} onChange={handleChange} />
                                        </div>
                                        <div class="form-group">
                                            <label>Phone *</label>
                                            <input type="text" class="form-control form-control-md" name="bPhone" required value={BformData.bPhone} onChange={handleChange} /> 
                                        </div>
                                    </div>
                                </div>
                                <div class="row gutter-sm">
                                  <div class="col-md-6">
                                      <div class="form-group">
                                        <label>Country / Region *</label>
                                        <div class="select-box">
                                        <select name="bContry" class="form-control form-control-md" onChange={(e) => setSelectedbCountry(e.target.value)} >
                                        <option value={selectedbCountry} hidden selected>{BformData.bContry} </option>     
                                            <option value="ind">India</option>
                                            <option value="usa">United States</option>
                                            <option value="uk">United Kingdom</option>
                                            <option value="fra">France</option>
                                            <option value="aus">Australia</option>
                                            <option value="can">Canada</option>
                                            <option value="nz">New Zealand</option>
                                            <option value="ger">Germany</option>
                                            <option value="net">Netherlands</option>
                                            <option value="den">Denmark</option>
                                            <option value="ita">Italy</option>
                                            <option value="ire">Ireland</option>
                                            <option value="bel">Belgium</option>
                                            <option value="por">Portugal</option>
                                            <option value="swe">Sweden</option>
                                            <option value="sin">Singapore</option>
                                            <option value="dub">Dubai</option>
                                            <option value="mal">Malaysia</option>
                                         </select>
                                      </div>
                                    </div>
                                  </div>

                                  <div class="col-md-6">
                                    <div class="form-group mb-7">
                                      <label>Email address *</label>
                                      <input type="email" class="form-control form-control-md" name="bEmail" required value={BformData.bEmail} onChange={handleChange} />
                                    </div>
                                  </div>

                                </div>
                                


                                <div class="form-group checkbox-toggle pb-2">
                                    <input type="checkbox" class="custom-checkbox" id="shipping-toggle"
                                        name="shipping-toggle" onClick={handleShipAddress} />
                                    <label for="shipping-toggle" onClick={handleShipAddress}>Ship to a different address?</label>
                                </div>


                                <div class="checkbox-content" style={{display: 'none'}}>
                                <h3 class="title billing-title text-uppercase ls-10 pt-1 pb-3 mb-0"> Shipping Details </h3>
                                    
                                    <div class="row gutter-sm">
                                    <div class="col-xs-6">
                                        <div class="form-group">
                                            <label>First name *</label>
                                            <input type="text" class="form-control form-control-md" name="sFirstname"
                                                 value={SformData.sFirstname} onChange={handleShipChange} />
                                        </div>
                                    </div>
                                    <div class="col-xs-6">
                                        <div class="form-group">
                                            <label>Last name *</label>
                                            <input type="text" class="form-control form-control-md" name="sLastname"
                                                 value={SformData.sLastname} onChange={handleShipChange} />
                                        </div>
                                    </div>
                                </div>
                               
                               
                                <div class="form-group">
                                    <label>Street address *</label>
                                    <input type="text" placeholder="House number and street name"
                                        class="form-control form-control-md mb-2" name="sAdrs1" value={SformData.sAdrs1} onChange={handleShipChange} />
                                    <input type="text" placeholder="Apartment, suite, unit, etc. (optional)"
                                        class="form-control form-control-md" name="sAdrs2" value={SformData.sAdrs2} onChange={handleShipChange} />
                                </div>
                                <div class="row gutter-sm">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label>Town / City *</label>
                                            <input type="text" class="form-control form-control-md" name="sTown" value={SformData.sTown} onChange={handleShipChange} />
                                        </div>
                                        <div class="form-group">
                                            <label>ZIP *</label>
                                            <input type="text" class="form-control form-control-md" name="sZip" value={SformData.sZip} onChange={handleShipChange} />
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label>State *</label>
                                            <input type="text" class="form-control form-control-md" name="sState" value={SformData.sState} onChange={handleShipChange} />
                                        </div>
                                        <div class="form-group">
                                            <label>Phone *</label>
                                            <input type="text" class="form-control form-control-md" name="sPhone" value={SformData.sPhone} onChange={handleShipChange} /> 
                                        </div>
                                    </div>
                                </div>
                                <div class="row gutter-sm">
                                  <div class="col-md-6">
                                      <div class="form-group">
                                        <label>Country / Region *</label>
                                        <div class="select-box">
                                          <select name="sContry" class="form-control form-control-md" onChange={(e) => setSelectedsCountry(e.target.value)}>
                                            <option value={selectedsCountry} hidden selected>{SformData.sContry} </option>     
                                            <option value="ind">India</option>
                                            <option value="usa">United States</option>
                                            <option value="uk">United Kingdom</option>
                                            <option value="fra">France</option>
                                            <option value="aus">Australia</option>
                                            <option value="can">Canada</option>
                                            <option value="nz">New Zealand</option>
                                            <option value="ger">Germany</option>
                                            <option value="net">Netherlands</option>
                                            <option value="den">Denmark</option>
                                            <option value="ita">Italy</option>
                                            <option value="ire">Ireland</option>
                                            <option value="bel">Belgium</option>
                                            <option value="por">Portugal</option>
                                            <option value="swe">Sweden</option>
                                            <option value="sin">Singapore</option>
                                            <option value="dub">Dubai</option>
                                            <option value="mal">Malaysia</option>
                                         </select>
                                      </div>
                                    </div>
                                  </div>

                                  <div class="col-md-6">
                                    <div class="form-group mb-7">
                                      <label>Email address *</label>
                                      <input type="email" class="form-control form-control-md" name="sEmail" value={SformData.sEmail} onChange={handleShipChange} />
                                    </div>
                                  </div>
                                  
                                </div>


                                </div>

                                <div class="form-group mt-3">
                                    <label for="order-notes">Order notes (optional)</label>
                                    <textarea class="form-control mb-0" id="order-notes" name="orderNotes" cols="30"
                                        rows="4"
                                        placeholder="Notes about your order, e.g special notes for delivery" value={BformData.orderNotes} onChange={handleChange}></textarea>
                                </div>
                            </div>



                            <div class="col-lg-5 mb-4 sticky-sidebar-wrapper">
                                <div class="order-summary-wrapper sticky-sidebar">
                                    <h3 class="title text-uppercase ls-10">Your Order</h3>
                                    <div class="order-summary">
                                        <table class="order-table">
                                            <thead>
                                                <tr>
                                                    <th style={{textAlign: 'left'}}> <b>Product</b> </th>
                                                    <th style={{textAlign: 'left'}}> <b>Price</b> </th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                            {cartItems.map((item, index) => (
                                                <tr class="bb-no">
                                                    <td class="product-name">{item.productName} {item.productWeight}g <i
                                                            class="fas fa-times"></i> <span
                                                            class="product-quantity">{item.productQty}</span></td>
                                                    <td class="product-total">₹ {Number(item.productPrice * item.productQty).toFixed(2)}</td>
                                                </tr>
                                            ))}

                                                <hr class="divider" />

                                                <tr class="cart-subtotal bb-no">
                                                    <td><b>Sub Total</b></td>
                                                    <td><b>₹ {Number(totalRate-gstRate).toFixed(2)}</b></td>
                                                </tr>

                                                <tr class="cart-subtotal bb-no">
                                                    <td>Shipping</td>
                                                    <td><b>₹ {Number(shippingRate).toFixed(2)}</b></td>
                                                </tr>

                                                <tr class="cart-subtotal bb-no">
                                                    <td>Discount</td>
                                                    <td><b class='text-danger'>{couponDisc > 0 ? '-' : ''} ₹ {Number(couponDisc).toFixed(2)}</b></td>
                                                </tr>

                                                <tr class="cart-subtotal bb-no">
                                                    <td>Tax</td>
                                                    <td><b>₹ {Number(gstRate).toFixed(2)}</b></td>
                                                </tr>
                                                <tr class="cart-subtotal bb-no">
                                                    <td><b>Grand Total</b></td>
                                                    <td><b class='text-success'>₹ {Number(grandTotal).toFixed(2)}</b></td>
                                                </tr>


                                            </tbody>
                                            {/* <tfoot>
                                                <tr class="shipping-methods">
                                                    <td colspan="2" class="text-left">
                                                        <h4 class="title title-simple bb-no mb-1 pb-0 pt-3 mb-3">Shipping 
                                                        </h4>
                                                        <ul id="shipping-method" class="mb-4">
                                                            <li>
                                                                <div class="custom-radio">
                                                                    <input type="radio" id="free-shipping"
                                                                        class="custom-control-input" name="shipping" />
                                                                    <label for="free-shipping"
                                                                        class="custom-control-label color-dark">Shipping</label>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div class="custom-radio">
                                                                    <input type="radio" id="local-pickup"
                                                                        class="custom-control-input" name="shipping" />
                                                                    <label for="local-pickup"
                                                                        class="custom-control-label color-dark">Store Pickup</label>
                                                                </div>
                                                            </li>
                                                           
                                                        </ul>
                                                    </td>
                                                </tr>
                                            </tfoot> */}
                                        </table>

                                        <div class="payment-methods mt-10" id="payment_method" >
                                            <h4 class="title font-weight-bold ls-25 pb-0 mb-1">Payment Methods</h4>
                                            <div class="accordion payment-accordion">
                                                <div class="card">
                                                    <div class="card-header">
                                                        <a href="#cash-on-delivery" class="collapse">Direct Bank Transfor, UPI Payments , Debit/Credit Cards</a>
                                                    </div>
                                                    <div id="cash-on-delivery" class="card-body expanded">
                                                        <p class="mb-0">
                                                            Make your payment directly into our bank account. 
                                                            Please use your Order ID as the payment reference. 
                                                            Your order will not be shipped until the funds have cleared in our account.
                                                            <br />
                                                            <b class='text-danger'>IMP: Please do not refresh or press back button until you'll recieve a confirmation message about your order from Jampani Pickles.</b>
                                                        </p>
                                                    </div>
                                                </div>
                                               
                                               
                                            </div>
                                        </div>

                                        <div class="form-group place-order pt-6">
                                            {paymentId.length === 0 ? isButton === false ?
                                              <button type="submit" class="btn btn-dark btn-block btn-rounded">Confirm & Continue</button>
                                              : <p class="btn btn-dark btn-block btn-rounded">Creating Order please wait...</p>
                                              :
                                              <a onClick={triggerPayment} class="btn btn-primary btn-block btn-rounded">Pay Now</a>
                                            }
                                            
                                            <Razorpay
                                              Amount = {grandTotal} 
                                              razId = {paymentId} 
                                              orderId = {orderId}   
                                              Name = {BformData.bFirstname}
                                              Email = {BformData.bEmail}
                                              Phone ={BformData.bPhone}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                     </form>
                     </>
                    )}

                </div>
                :
                <div class="container">
                    <div class="row">
                      <div class="col-lg-12" style={{marginTop: '15vh', marginBottom: '35vh'}} align="center">
                         <h4>There is no Items in your Cart! Please add Items to Continue</h4>
                         <a href="/" class="btn btn-primary">Shop Now</a>
                      </div>
                    </div>
                </div>
                }
            </div>
          
          
        </main>
          <Footer />
        </div>
  );
}

export default App;