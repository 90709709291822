import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ReactSession } from 'react-client-session';
import { useNavigate, useParams } from 'react-router-dom';
import Header from './header';
import Footer from './footer';
ReactSession.setStoreType("localStorage");

function App() {
    
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');


    const login = async (e) => {
     e.preventDefault();

     const loginData = {
        email: email,
        pass : password
     }
     try{
        const response = await axios.post('https://demo.sninfoserv.com/api/customer/login/', loginData);
        //console.log(response.data);
        if(response.data.status === 'success'){
            sessionStorage.setItem('jmpiUserId', response.data.userId);
            window.location ='/my-account';
        }else{
            setError(response.data.message);
        }

     }catch(error){
        setError({ error: error.message });
     }

    };
  
    return (
        <div class="page-wrapper">
          <h1 class="d-none">Jampani Pickles</h1>
          <Header />  
          <main class="main order">
           
          <nav class="breadcrumb-nav">
                <div class="container">
                    <ul class="breadcrumb">
                        <li><a href="/">Home</a></li>
                        <li>My account</li>
                    </ul>
                </div>
            </nav>

            <div class="page-content" >
                <div class="container">
                    <div class="login-popup" style={{marginLeft: 'auto', marginRight: 'auto'}}>
                        <div class="tab tab-nav-boxed tab-nav-center tab-nav-underline">
                            <ul class="nav nav-tabs text-uppercase" role="tablist">
                                <li class="nav-item">
                                    <a href="#sign-in" class="nav-link active">Sign In</a>
                                </li>
                                {/* <li class="nav-item">
                                    <a href="#sign-up" class="nav-link">Sign Up</a>
                                </li> */}
                            </ul>


                            <div class="tab-content">
                               
                                <div class="tab-pane active" id="sign-in">
                                  <form onSubmit={login}>  
                                    <div class="form-group">
                                        <label>Email ID *</label>
                                        <input type="email" class="form-control"  required  value={email} onChange={(e)=>setEmail(e.target.value)} />
                                    </div>
                                    <div class="form-group mb-0">
                                        <label>Password *</label>
                                        <input type="password" class="form-control"  required value={password} onChange={(e)=> setPassword(e.target.value)} />
                                    </div>
                                    <div class="form-checkbox d-flex align-items-center justify-content-between">
                                        <input type="checkbox" class="custom-checkbox" id="remember1" name="remember1" required="" />
                                        <label for="remember1">Remember me</label>
                                        <a href="/login">Lost your password?</a>
                                    </div>
                                    <button type="submit" class="btn btn-primary w-100">Sign In</button>
                                    </form>
                                    <p class="text-danger" align="center">{error.length > 0 && error}</p>
                                </div>
                               
                              

                                <div class="tab-pane" id="sign-up">
                                    <div class="form-group">
                                        <label>Your email address *</label>
                                        <input type="text" class="form-control" name="email_1" id="email_1" required />
                                    </div>
                                    <div class="form-group mb-5">
                                        <label>Password *</label>
                                        <input type="text" class="form-control" name="password_1" id="password_1" required />
                                    </div>
                                    <div class="checkbox-content login-vendor">
                                        <div class="form-group mb-5">
                                            <label>First Name *</label>
                                            <input type="text" class="form-control" name="first-name" id="first-name" required />
                                        </div>
                                        <div class="form-group mb-5">
                                            <label>Last Name *</label>
                                            <input type="text" class="form-control" name="last-name" id="last-name" required />
                                        </div>
                                        <div class="form-group mb-5">
                                            <label>Shop Name *</label>
                                            <input type="text" class="form-control" name="shop-name" id="shop-name" required />
                                        </div>
                                        <div class="form-group mb-5">
                                            <label>Shop URL *</label>
                                            <input type="text" class="form-control" name="shop-url" id="shop-url" required />
                                            <small>https://d-themes.com/wordpress/wolmart/demo-1/store/</small>
                                        </div>
                                        <div class="form-group mb-5">
                                            <label>Phone Number *</label>
                                            <input type="text" class="form-control" name="phone-number" id="phone-number" required />
                                        </div>
                                    </div>
                                    <div class="form-checkbox user-checkbox mt-0">
                                        <input type="checkbox" class="custom-checkbox checkbox-round active" id="check-customer" name="check-customer" required="" />
                                        <label for="check-customer" class="check-customer mb-1">I am a customer</label>
                                        <br />
                                        <input type="checkbox" class="custom-checkbox checkbox-round" id="check-seller" name="check-seller" required="" />
                                        <label for="check-seller" class="check-seller">I am a vendor</label>
                                    </div>
                                    <p>Your personal data will be used to support your experience 
                                        throughout this website, to manage access to your account, 
                                        and for other purposes described in our <a href="#" class="text-primary">privacy policy</a>.</p>
                                    <a href="#" class="d-block mb-5 text-primary">Signup as a vendor?</a>
                                    <div class="form-checkbox d-flex align-items-center justify-content-between mb-5">
                                        <input type="checkbox" class="custom-checkbox" id="remember" name="remember" required="" />
                                        <label for="remember" class="font-size-md">I agree to the <a  href="#" class="text-primary font-size-md">privacy policy</a></label>
                                    </div>
                                    <a href="#" class="btn btn-primary">Sign Un</a>
                                </div>

                            </div>

                            
                        </div>
                    </div>
                </div>
            </div>
           
        </main>
          <Footer />
        </div>
  );
}

export default App;