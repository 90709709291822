import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ReactSession } from 'react-client-session';
import Header from './header';
import Footer from './footer';
ReactSession.setStoreType("localStorage");

function App() {

    const [cartItems, setCartItems] = useState([]);
    const [error, setError] = useState('');
    const [totalRate, setTotal] = useState(0);
    const [gstRate, setGst] = useState(0);
    const [couponDisc, setCouponDisc] = useState(0);
    const [coupon, setCoupon] = useState('');

    const carts =() =>{
        const storedCartItems = JSON.parse(sessionStorage.getItem('cartItems')) || [];
        setCartItems(storedCartItems);
    };
    
    useEffect(() => {
        carts();
    }, [cartItems]);


    const removeFromCart = (productID, productWeights) => {
        const existingCartItems = JSON.parse(sessionStorage.getItem('cartItems')) || [];
        const updatedCartItems = existingCartItems.filter(
            item => !(item.productId === productID && item.productWeight === productWeights)
        );
        sessionStorage.setItem('cartItems', JSON.stringify(updatedCartItems));
    };

    

    useEffect(() =>{
        let tRate = 0;
        let tGst = 0;
        cartItems && cartItems.forEach(item =>{
            tRate += (item.productPrice * item.productQty);
            tGst += Number((item.productPrice - (item.productPrice / (1 + (item.productGst / 100))))*item.productQty);
        });
        setTotal(tRate);
        setGst(tGst);
    },[cartItems]);



    const couponCode = (e) => {
      const input = e.target.value;
      setCoupon(input);
    };

    const applyCoupon = () => {
        const diccount = 10;
        setCouponDisc(diccount);
    };

    
    return (
        <div class="page-wrapper">
          <h1 class="d-none">Jampani Pickles</h1>
          <Header />  
          <main class="main cart">
           
            <nav class="breadcrumb-nav">
                <div class="container">
                    <ul class="breadcrumb shop-breadcrumb bb-no">
                        <li class="active"><a href="/cart">Shopping Cart</a></li>
                        <li><a href="/checkout">Checkout</a></li>
                        <li><a>Order Complete</a></li>
                    </ul>
                </div>
            </nav>
           

           
            <div class="page-content">
            {cartItems.length > 0 ?
                <div class="container">
                    <div class="row gutter-lg mb-10">
                        <div class="col-lg-8 pr-lg-4 mb-6">


                                 
                            <table class="shop-table cart-table">
                                <thead>
                                    <tr>
                                        <th class="product-price"><span>Product</span></th>
                                        <th class="product-price"><span>Price</span></th>
                                        <th class="product-quantity"><span>Quantity</span></th>
                                        <th class="product-subtotal"><span>Subtotal</span></th>
                                    </tr>
                                </thead>
                                <tbody>
                                {cartItems.map((item, index) => (
                                    <tr>
                                        <td class="product-thumbnail">
                                          <div class="product product-cart">
                                            <figure class="product-media">
                                              <a href={`/product?sku=${item.productId}`}>
                                               <img src={`https://demo.sninfoserv.com/api/products/images/${item.productImage}`} alt={item.productName} width="300" height="338" />
                                               <a href={`/product?sku=${item.productId}`}>{item.productName} {item.productWeight}g</a>
                                              </a>
                                            </figure>
                                            <button class="btn btn-link btn-close" style={{float: 'left', left:'0'}} aria-label="button" onClick={() => removeFromCart(item.productId, item.productWeight)}>
                                               <i class="fas fa-times"></i>
                                            </button>
                                          </div>
                                        </td>
                                        <td class="product-subtotal" style={{textAlign: 'center'}}><span class="amount">₹ {Number(item.productPrice).toFixed(2)}</span></td>
                                        <td class="product-quantity">
                                            <div class="input-group">
                                                <input class="quantity form-control" type="number" min="1" value={item.productQty} max="100000" />
                                                <button class="quantity-plus w-icon-plus"></button>
                                                <button class="quantity-minus w-icon-minus"></button>
                                            </div>
                                        </td>
                                        <td class="product-price" style={{textAlign: 'center'}}>
                                            <span class="amount">₹ {Number(item.productPrice * item.productQty).toFixed(2)}</span>
                                        </td>
                                    </tr>
                                  ))}

                                   
                                </tbody>
                            </table>

                            <div class="cart-action mb-6 mt-6">
                                <a href="#" class="btn btn-dark btn-rounded btn-icon-left btn-shopping mr-auto"><i class="w-icon-long-arrow-left"></i>Continue Shopping</a>
                            </div>

                           
                        </div>
                        <div class="col-lg-4 sticky-sidebar-wrapper">
                            <div class="sticky-sidebar">
                                <div class="cart-summary mb-4">

                                <h5 class="title coupon-title font-weight-bold text-uppercase">Coupon Discount</h5>

                                <div class="cart-subtotal d-flex align-items-center justify-content-between mb-4">
                                  <input type="text" class="form-control mr-2" placeholder="Enter coupon code here..." value={coupon} required onChange={couponCode} />
                                  <button class="btn btn-dark btn-outline btn-rounded" onClick={applyCoupon}>Apply Coupon</button>
                                </div>

                                    <h3 class="cart-title text-uppercase">Cart Totals</h3>
                                    <div class="cart-subtotal d-flex align-items-center justify-content-between">
                                        <label class="ls-25">Subtotal</label>
                                        <span>₹ {Number(totalRate-gstRate).toFixed(2)}</span>
                                    </div>

                                    <hr class="divider" />

                                    


                                    <div class="cart-subtotal d-flex align-items-center justify-content-between">
                                        <label class="ls-25">Discount </label>
                                        <span class='text-danger'> {couponDisc > 0 ? '-' : ''} ₹ { Number(couponDisc).toFixed(2)}</span>
                                    </div>

                                    <div class="cart-subtotal d-flex align-items-center justify-content-between">
                                        <label class="ls-25">Tax </label>
                                        <span>₹ {Number(gstRate).toFixed(2)}</span>
                                    </div>

                                    

                                    <hr class="divider mb-6 mt-2" />
                                    <div class="order-total d-flex justify-content-between align-items-center">
                                        <label>Total</label>
                                        <span class="ls-50">₹ {(Number(totalRate) - Number(couponDisc)).toFixed(2)}</span>
                                    </div>
                                    <a href="/checkout"
                                        class="btn btn-block btn-dark btn-icon-right btn-rounded  btn-checkout mt-5">
                                        Proceed to checkout<i class="w-icon-long-arrow-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <div class="container">
                    <div class="row">
                      <div class="col-lg-12" style={{marginTop: '15vh', marginBottom: '35vh'}} align="center">
                         <h4>There is no Items in your Cart! Please add Items to Continue</h4>
                         <a href="/" class="btn btn-primary">Shop Now</a>
                      </div>
                    </div>
                </div>
                }
            </div>
         
        </main>
          <Footer />
        </div>
  );
}

export default App;