import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from './header';
import Footer from './footer';

function App() {


    const [productData, setProductData] = useState('');
    const [error, setError] = useState('');


    const getProduct = async () => {
        const url = 'https://demo.sninfoserv.com/api/products/view-products/';
        try {
          const res = await axios.get(url);
          setProductData(res.data);
          console.log(res.data);
        } catch (error) {
            setError({ error: error.message });
        }
    };
      
    useEffect(() => {
        getProduct();
    }, []);


    const getRandomSubset = (array, size) => {
        const shuffled = array.sort(() => 0.5 - Math.random());
        return shuffled.slice(0, size);
    };


    return (
        <div class="page-wrapper">
        <h1 class="d-none">Jampani Pickles</h1>
      
        <Header /> 
        <main class="main mb-10 pb-1">
         
         <nav class="breadcrumb-nav container">
             <ul class="breadcrumb bb-no">
                 <li><a href="/">Home</a></li>
                 <li><a href="/">All Products</a></li>
                
             </ul>
         </nav>
         

        <div class="page-content">
            <div class="container">
                <div class="row gutter-lg mb-10">
                    
                    <h4 align="center" class="mb-6 mt-6">All Products</h4>
                    
                    {productData && productData.data && getRandomSubset(productData.data, 50).map((product, index) => (
                     <div class="col-xl-3 col-lg-3 col-md-6 col-6 pr-lg-4 mb-6">
                        <div class="product-wrap sm-item">
                            <div class="product text-center">
                                <figure class="product-media">
                                    <a href={`/product?sku=${product.product_code}`}>
                                        <img src={`https://demo.sninfoserv.com/api/products/images/${product.images[0].image}`} alt={product.product} width="300" height="338" />
                                        
                                    </a>
                                    <div class="product-action-vertical">
                                        <a href={`/product?sku=${product.product_code}`} class="btn-product-icon  w-icon-cart"
                                            title="Add to cart"></a>
                                        <a href="#" class="btn-product-icon btn-wishlist w-icon-heart"
                                            title="Add to wishlist"></a>
                                        <a href="#" class="btn-product-icon btn-quickview w-icon-search"
                                            title="Quickview"></a>
                                        <a href="#" class="btn-product-icon btn-compare w-icon-compare"
                                            title="Add to Compare"></a>
                                    </div>
                                    <div class="product-label-group">
                                        <label class="product-label label-new">New</label>
                                    </div>
                                </figure>
                                <div class="product-details">
                                    <h4 class="product-name"><a href={`/product?sku=${product.product_code}`}>{product.product}</a></h4>
                                     
                                    <div class="product-price">
                                        <ins class="new-price">₹ {Number(product.variations[0].price).toFixed(2)}</ins>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                        ))}
                </div>
            </div>
        </div>

        </main>
        <Footer />
    
</div>
  );
}

export default App;
