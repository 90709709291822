import React, { useEffect, useState } from 'react';
import { ReactSession } from 'react-client-session';
import { useNavigate, useParams } from 'react-router-dom';
ReactSession.setStoreType("localStorage");

function App() {

    const navigate = useNavigate();
    const userId = sessionStorage.getItem('jmpiUserId');

    const logout1 = () => {
        sessionStorage.removeItem('jmpiUserId');
        window.location = '/login';
    };

    const openMenu = () => {
        const body = document.body;
        if (body.classList.contains('mmenu')) {
            body.classList.remove('mmenu', 'mmenu-active');
        } else {
            body.classList.add('mmenu', 'mmenu-active');
        }
    };

    return (
        <>
        <footer class="footer appear-animate" data-animation-options="{
            'name': 'fadeIn'
        }">
            <div class="footer-newsletter bg-primary pt-6 pb-6">
                <div class="container">
                    <div class="row justify-content-center align-items-center">
                        <div class="col-xl-5 col-lg-6">
                            <div class="icon-box icon-box-side text-white">
                                <div class="icon-box-icon d-inline-flex">
                                    <i class="w-icon-envelop3"></i>
                                </div>
                                <div class="icon-box-content">
                                    <h4 class="icon-box-title text-white text-uppercase mb-0">Subscribe To Our
                                        Newsletter</h4>
                                    <p class="text-white">Get all the latest information on Events, Sales and Offers.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-7 col-lg-6 col-md-9 mt-4 mt-lg-0 ">
                            <form action="#" method="get"
                                class="input-wrapper input-wrapper-inline input-wrapper-rounded">
                                <input type="email" class="form-control mr-2 bg-white" name="email" id="email"
                                    placeholder="Your E-mail Address" />
                                <button class="btn btn-dark btn-rounded" type="submit">Subscribe<i
                                        class="w-icon-long-arrow-right"></i></button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="footer-top">
                    <div class="row">
                        <div class="col-lg-4 col-sm-6">
                            <div class="widget widget-about">
                                <a href="/" class="logo-footer">
                                    <img src="assets/images/logo_footer.png" alt="logo-footer" width="144"
                                        height="45" />
                                </a>
                                <div class="widget-body">
                                    <p class="widget-about-title">Got Question? Call us 24/7</p>
                                    <a href="tel:+919985574967" class="widget-about-call">+91 99855 74967</a>
                                    <a href="tel:+917416720111" class="widget-about-call">+91 74167 20111</a>
                                   
    
                                    <div class="social-icons social-icons-colored">
                                        <a href="#" class="social-icon social-facebook w-icon-facebook"></a>
                                        <a href="#" class="social-icon social-twitter w-icon-twitter"></a>
                                        <a href="#" class="social-icon social-instagram w-icon-instagram"></a>
                                        <a href="#" class="social-icon social-youtube w-icon-youtube"></a>
                                        <a href="#" class="social-icon social-pinterest w-icon-pinterest"></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-sm-6">
                            <div class="widget">
                                <h3 class="widget-title">Company</h3>
                                <ul class="widget-body">
                                    <li><a href="/">Home</a></li>
                                    <li><a href="/">About Us</a></li>
                                    <li><a href="/">Contact Us</a></li>
                                    <li><a href="/">Our Branches</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-3 col-sm-6">
                            <div class="widget">
                                <h4 class="widget-title">My Account</h4>
                                <ul class="widget-body">
                                    <li><a href="/">Account</a></li>
                                    <li><a href="/">Track Order</a></li>
                                    <li>{userId > 0 ? <a onClick={logout1}>Logout</a> : <a href="/login">Login</a> }</li>
                                    <li><a href="/">Register</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-3 col-sm-6">
                            <div class="widget">
                                <h4 class="widget-title">Customer Service</h4>
                                <ul class="widget-body">
                                    <li><a href="/">Privacy Policy</a></li>
                                    <li><a href="/">Terms of Service</a></li>
                                    <li><a href="/">Refund Policy</a></li>
                                    <li><a href="/">Shipping Policy</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
             
                <div class="footer-bottom">
                    <div class="footer-left">
                        <p class="copyright">Copyright © 2024 Jampani Pickles. All Rights Reserved. Web Solutions By <a href="mailto:bizzoters@gmail.com"> Bizzoters</a></p>
                    </div>
                    <div class="footer-right">
                        <span class="payment-label mr-lg-8">We're using safe payment for</span>
                        <figure class="payment">
                            <img src="assets/images/payment.png" alt="payment" width="159" height="25" />
                        </figure>
                    </div>
                </div>
            </div>
        </footer>




<div class="sticky-footer sticky-content fix-bottom">
   <a href="/" class="sticky-link active">
       <i class="w-icon-home"></i>
       <p>Home</p>
   </a>
   <a href="/all-products" class="sticky-link">
       <i class="w-icon-category"></i>
       <p>Shop</p>
   </a>
   <a href="/" class="sticky-link">
       <i class="w-icon-account"></i>
       <p>Account</p>
   </a>
   <div class="cart-dropdown dir-up">
       <a href="/cart" class="sticky-link">
           <i class="w-icon-cart"></i>
           <p>Cart</p>
       </a>
   </div>

  
</div>

<a id="scroll-top" class="scroll-top" href="#top" title="Top" role="button"> <i class="w-icon-angle-up"></i> <svg
       version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 70 70">
       <circle id="progress-indicator" fill="transparent" stroke="#000000" stroke-miterlimit="10" cx="35" cy="35"
           r="34" style={{strokeDasharray: '16.4198, 400'}}></circle>
   </svg> 
</a>

<div class="mobile-menu-wrapper">
   <div class="mobile-menu-overlay" onClick={openMenu}></div>
  

   <a href="javascript:void(0)" class="mobile-menu-close" onClick={openMenu}><i class="close-icon"></i></a>


   <div class="mobile-menu-container scrollable">
       <form action="#" method="get" class="input-wrapper">
           <input type="text" class="form-control" name="search" autocomplete="off" placeholder="Search"
               required />
           <button class="btn btn-search" type="submit">
               <i class="w-icon-search"></i>
           </button>
       </form>
     
       <div class="tab">
           <ul class="nav nav-tabs" role="tablist">
               <li class="nav-item">
                   <a href="#main-menu" class="nav-link active">Menu</a>
               </li>
               <li class="nav-item">
                   <a href="#categories" class="nav-link">Products</a>
               </li>
           </ul>
       </div>
       <div class="tab-content">
           <div class="tab-pane active" id="main-menu">
               <ul class="mobile-menu">
                   <li><a href="/">Home</a></li>
                   <li><a href="/">About US</a></li>
                   <li><a href="/">Register</a></li>
                   <li><a href="/">Track Orders</a></li>
                   <li><a href="/">Contact Us</a></li>
                   <li>{userId > 0 ? <a onClick={logout1}>Logout</a> : <a href="/login">Login</a> }</li>
                  
               </ul>
           </div>
           <div class="tab-pane" id="categories">
               <ul class="mobile-menu">
                   <li><a href="/view-products?main-category=NRI Recomends">NRI Recomends</a></li>
                       <li><a href="/view-products?main-category=Pickles">Pickles</a>
                           <ul>
                               <li><a href="/view-products?category=Veg Pickles">Veg Pickles</a>
                                   <ul>
                                       <li><a href="/view-products?category=Veg Pickles">All Veg Pickles</a></li>
                                   </ul>
                               </li>
                               <li><a href="/view-products?category=Non Veg Pickles">Non-Veg Pickles</a>
                                    <ul>
                                       <li><a href="/view-products?sub-category=Mutton Pickles">Mutton Pickles</a></li>
                                       <li><a href="/view-products?sub-category=Chicken Pickles">Chicken Pickles</a></li>
                                       <li><a href="/view-products?sub-category=Fish Pickles">Fish Pickles</a></li>
                                       <li><a href="/view-products?sub-category=Prawn Pickles">Prawn Pickles</a></li>
                                       <li><a href="/view-products?sub-category=Mixed Non-Veg Pickles">Mixed Non-Veg Pickles</a></li>
                                       <li><a href="/view-products?category=Non Veg Pickles">View All</a></li>
                                   </ul>
                               </li>
                           </ul>
                       </li>
                       <li><a href="/view-products?main-category=Sweets">Sweets</a>
                           <ul>
                               <li><a href="/view-products?category=Ghee Sweets">Ghee Sweets</a></li>
                               <li><a href="/view-products?category=Kaju Sweets">Kaju Sweets</a></li>
                               <li><a href="/view-products?category=Sugar-Free Sweets">Sugar-Free Sweets</a></li>
                               <li><a href="/view-products?category=Traditional Sweets">Traditional Sweets</a></li>
                               <li><a href="/view-products?category=Kalakand Sweets">Kalakand Sweets</a></li>
                               <li><a href="/view-products?main-category=Sweets">View All</a></li>
                           </ul>
                       </li>
                       <li><a href="/view-products?main-category=Combos">Combos</a></li>
                       <li><a href="/view-products?main-category=Namkeen">Namkeen</a></li>
                       <li><a href="/view-products?main-category=Savouries">Savouries</a></li>
                       <li><a href="/view-products?main-category=Podis">Podis</a></li>
                       <li><a href="/view-products?main-category=Fryums">Fryums</a></li>
                       <li><a href="/view-products?main-category=Instant Rice Mix">Instant Rice Mix</a></li>
                       <li><a href="/view-products?main-category=Specials">Specials</a></li>
                                          
               </ul>
           </div>
       </div>
   </div>
</div>
</>
    );
}
export default App;