import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ReactSession } from 'react-client-session';
import Header from './header';
import Footer from './footer';
ReactSession.setStoreType("localStorage");

function App() {

  const [productId, setProductId] = useState('');
  const [productData, setProductData] = useState('');
  const [productPrice, setPrice] = useState('');
  const [productKGPrice, setKGPrice] = useState('');
  const [error, setError] = useState('');
  const [productDatas, setProductDatas] = useState('');
  const [productQty, setProductQty] = useState(1);
  const [productWeight, setVar] = useState('');
  const [proGst, setGst] = useState(0);
    
 // sessionStorage.setItem('cartItems', JSON.stringify([])); 
 
    const extractProductId = () => {
      const url = window.location.href; 
      const queryString = url.split('?')[1] || ''; 
      const params = new URLSearchParams(queryString);
      const product = params.get('sku');
      if (product) {
        setProductId(product);    
      }
    };
    
    useEffect(() => {
        extractProductId();
    }, [productId]);

    const getProduct = async () => {
      const url = 'https://demo.sninfoserv.com/api/products/view-products/';
      const data = {
        productCode: productId,
      };
      const token = 'coNnecTwItJaMpAnI';

      const options = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
      };

      try {
        const res = await axios.post(url, data, options);
        setProductData(res.data);
        const price = res.data && res.data.data && res.data.data[0].variations[0].price;
        const weight = res.data && res.data.data && res.data.data[0].variations[0].weight;
        const gst = res.data && res.data.data && res.data.data[0].variations[0].gst;
        const split = (1000/weight).toFixed(2);
        const ratekg = (price*split).toFixed(2)
        setPrice(price);
        setKGPrice(ratekg);
        setVar(weight);
        setGst(gst);

        //console.log(res.data);
      } catch (error) {
          //console.error('Error:', error);
          setError({ error: error.message });
      }
    };
    
    useEffect(() => {
        if(productId){
            getProduct();
            
        }
    }, [productId]);

    const selectVar = (index) => {
      setPrice(productData && productData.data && productData.data[0].variations[index].price);
      setVar(productData && productData.data && productData.data[0].variations[index].weight);
      setGst(productData && productData.data && productData.data[0].variations[index].gst);
    };
    

    const getProducts = async () => {
        const url = 'https://demo.sninfoserv.com/api/products/view-products/';
        try {
          const res = await axios.get(url);
          setProductDatas(res.data);
          //console.log(res.data);
        } catch (error) {
            setError({ error: error.message });
        }
    };
      
    useEffect(() => {
        getProducts();
    }, []);

    const getRandomSubset = (arr, size) => {
        let shuffled = arr.slice(0); 
        for (let i = shuffled.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
        }
        return shuffled.slice(0, size);
    };
    
    const randomSubsetOfProducts = productDatas && productDatas.data && 
        getRandomSubset( productDatas.data.filter(products => products.category === (productData && productData.data && productData.data[0].category)), 10
    );

    const incQty = (qty) => {
        if(qty === 'plus'){
           setProductQty(productQty+1);
        }else{
           setProductQty(productQty-1);
        }
     };

     const addToCart = (productIds, qty, weights) => {

        const existingCartItems = JSON.parse(sessionStorage.getItem('cartItems')) || [];

        const existingItemIndex = existingCartItems.findIndex(
            item => item.productId === productIds && item.productWeight === weights
        );
    
        if (existingItemIndex !== -1) {
            existingCartItems[existingItemIndex].productQty += qty;
        } else {    
            const newItem = {
                productId: productIds,
                productName: productData && productData.data && productData.data[0].product,
                productGst: proGst,
                productImage: productData && productData.data && productData.data[0].images && productData.data[0].images[0].image,
                productPrice: productPrice,
                productQty: qty,
                productWeight: weights,
                productGst: proGst
            };
            existingCartItems.push(newItem);
        }
        sessionStorage.setItem('cartItems', JSON.stringify(existingCartItems));
    };
    
    //console.log(JSON.parse(sessionStorage.getItem('cartItems')));
  return (
    <div class="page-wrapper">
    <h1 class="d-none">Jampani Pickles</h1>
  
    <Header />  

    
    <main class="main mb-10 pb-1">
         
         <nav class="breadcrumb-nav container">
             <ul class="breadcrumb bb-no">
                 <li><a href="/">Home</a></li>
                 <li><a href="/">Products</a></li>
                 <li>{productData && productData.data && productData.data[0].category}</li>
             </ul>
            
         </nav>
      
         <div class="page-content">
             <div class="container">
                 <div class="product product-single row">
                     <div class="col-md-6 mb-6">
                         <div class="product-gallery product-gallery-sticky product-gallery-vertical">
                             <div class="swiper-container product-single-swiper swiper-theme nav-inner" data-swiper-options="{
                                 'navigation': {
                                     'nextEl': '.swiper-button-next',
                                     'prevEl': '.swiper-button-prev'
                                 }
                             }">
                                 <div class="swiper-wrapper row cols-1 gutter-no">
                                 {productData && productData.data && productData.data[0].images && productData.data[0].images.map((productImages, index) => (
                                     <div class="swiper-slide">
                                         <figure class="product-image">
                                             <img src={`https://demo.sninfoserv.com/api/products/images/${productImages.image}`}
                                                 data-zoom-image={`https://demo.sninfoserv.com/api/products/images/${productImages.image}`}
                                                 alt={productData && productData.data && productData.data[0].product} width="800" height="900" />
                                         </figure>
                                     </div>
                                ))}
                                    
                                 </div>
                                 <button class="swiper-button-next"></button>
                                 <button class="swiper-button-prev"></button>
                                 
                             </div>
                             <div class="product-thumbs-wrap swiper-container" data-swiper-options="{
                                 'navigation': {
                                     'nextEl': '.swiper-button-next',
                                     'prevEl': '.swiper-button-prev'
                                 },
                                 'breakpoints': {
                                     '992': {
                                         'direction': 'vertical',
                                         'slidesPerView': 'auto'
                                     }
                                 }
                             }">
                                 <div class="product-thumbs swiper-wrapper row cols-lg-1 cols-4 gutter-sm">
                                  
                                 {productData && productData.data && productData.data[0].images && productData.data[0].images.map((productImages, index) => (
                                     <div class="product-thumb swiper-slide">
                                         <img src={`https://demo.sninfoserv.com/api/products/images/${productImages.image}`} alt={productData && productData.data && productData.data[0].product}
                                             width="800" height="900" />
                                     </div>
                                 ))}
                                     

                                 </div>
                                 <button class="swiper-button-prev"></button>
                                 <button class="swiper-button-next"></button>
                             </div>
                         </div>
                     </div>
                     
                     
                     <div class="col-md-6 mb-4 mb-md-6">
                         <div class="product-details">
                             <h1 class="product-title">{productData && productData.data && productData.data[0].product}</h1>
                             <div class="product-bm-wrapper">
                                 <div class="product-meta">
                                     <div class="product-categories">
                                         Category:
                                         <span class="product-category"><a href={`/product?sku=${productId}`}> {productData && productData.data && productData.data[0].category}</a></span>
                                     </div>
                                     <div class="product-sku">
                                         SKU: <span>{productId}</span>
                                     </div>
                                 </div>
                             </div>

                             {/*  <hr class="product-divider" />
                             
                             <div class="product-price"><ins class="new-price">₹ {parseFloat(productKGPrice).toFixed(2)} <span class="product-short-desc">/ kg</span></ins></div> */}

                             <div class="product-short-desc lh-2">
                                 <p class="list-type-check list-style-none">
                                 {productData && productData.data && productData.data[0].content}
                                 </p>
                             </div>

                             <hr class="product-divider" />
                             <div class="product-form product-variation-form product-size-swatch">
                                <label class="mb-1">Size:</label>
                                <div class="flex-wrap d-flex align-items-center product-variations">
                                {productData && productData.data && productData.data[0].variations.map((variation, index) => (
                                    <a key={index} onClick={() => selectVar(index)}  className={productPrice === variation.price ? "size active" : "size"}>{variation.weight}g</a>
                                ))}
                                </div>
                                
                             </div>

                                        <div class="product-price">
                                            <span >₹ {productPrice === '' ? 'Loading' : parseFloat(productPrice).toFixed(2)}</span>
                                        </div>
                             <hr class="product-divider" />

                             <div class="fix-bottom product-sticky-content sticky-content">
                                 <div class="product-form container">
                                     <div class="product-qty-form with-label">
                                         <label>Quantity:</label>
                                         <div class="input-group">
                                             <input class="quantity form-control" type="number" min="1" value={productQty}
                                                 max="100" />
                                             <button class="quantity-plus w-icon-plus"  onClick={() => incQty('plus')}></button>
                                             <button class="quantity-minus w-icon-minus" onClick={() => incQty('minus')}></button>
                                         </div>
                                     </div>
                                     <button class="btn btn-primary btn-cart" onClick={() => addToCart(productId, productQty, productWeight)}>
                                         <i class="w-icon-cart"></i>
                                         <span>Add to Cart</span>
                                     </button>
                                 </div>
                             </div>

                             <div class="social-links-wrapper">
                                 <div class="social-links">
                                     <div class="social-icons social-no-color border-thin">
                                         <a href="#" class="social-icon social-facebook w-icon-facebook"></a>
                                         <a href="#" class="social-icon social-twitter w-icon-twitter"></a>
                                         <a href="#" class="social-icon social-pinterest fab fa-pinterest-p"></a>
                                         <a href="#" class="social-icon social-whatsapp fab fa-whatsapp"></a>
                                         <a href="#" class="social-icon social-youtube fab fa-linkedin-in"></a>
                                     </div>
                                 </div>
                                 <span class="divider d-xs-show"></span>
                                 <div class="product-link-wrapper d-flex">
                                     <a href="#" class="btn-product-icon btn-wishlist w-icon-heart"><span></span></a>
                                     <a href="#" class="btn-product-icon btn-compare btn-icon-left w-icon-compare"><span></span></a>
                                 </div>
                             </div>
                         </div>
                     </div>
                 </div>
                 
            
                 <section class="related-product-section">
                     <div class="title-link-wrapper mb-4">
                         <h4 class="title">Related Products</h4>
                         <a href="#" class="btn btn-dark btn-link btn-slide-right btn-icon-right">More
                             Products<i class="w-icon-long-arrow-right"></i></a>
                     </div>
                     <div class="swiper-container swiper-theme" data-swiper-options="{
                         'spaceBetween': 20,
                         'slidesPerView': 2,
                         'breakpoints': {
                             '576': {
                                 'slidesPerView': 3
                             },
                             '768': {
                                 'slidesPerView': 4
                             },
                             '992': {
                                 'slidesPerView': 4
                             }
                         }
                     }">
                         <div class="swiper-wrapper row cols-lg-3 cols-md-4 cols-sm-3 cols-2">

                         {randomSubsetOfProducts && randomSubsetOfProducts.map((product, index) => (

                             <div class="swiper-slide product">
                                 <figure class="product-media">
                                     <a  href={`/product?sku=${product.product_code}`}>
                                         <img src={`https://demo.sninfoserv.com/api/products/images/${product.images[0].image}`} alt={product.product}
                                             width="300" height="338" />
                                     </a>
                                     <div class="product-action-vertical">
                                         <a  href={`/product?sku=${product.product_code}`} class="btn-product-icon  w-icon-cart"
                                             title="Add to cart"></a>
                                         <a href="#" class="btn-product-icon btn-wishlist w-icon-heart"
                                             title="Add to wishlist"></a>
                                         <a href="#" class="btn-product-icon btn-compare w-icon-compare"
                                             title="Add to Compare"></a>
                                     </div>
                                     <div class="product-action">
                                         <a href={`/product?sku=${product.product_code}`} class="btn-product btn-quickview1" title="Quick View">Quick
                                             View</a>
                                     </div>
                                 </figure>
                                 <div class="product-details">
                                     <h4 class="product-name"><a  href={`/product?sku=${product.product_code}`}>{product.product}</a></h4>
                                     <div class="product-pa-wrapper">
                                         <div class="product-price">₹ {Number(product.variations[0].price).toFixed(2)}</div>
                                     </div>
                                 </div>
                             </div>

                         ))}    

                             
                         </div>
                     </div>
                 </section>
             </div>
         </div>
      
     </main>
   
    <Footer />
    
    
</div>
  );
}

export default App;
