import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from './header';
import Footer from './footer';

function App() {
    
    const [productData, setProductData] = useState(null);
    const [error, setError] = useState('');

    const getProduct = async () => {
        const url = 'https://demo.sninfoserv.com/api/products/view-products/';
        try {
          const res = await axios.get(url);
          setProductData(res.data);
          console.log(res.data);
        } catch (error) {
            setError({ error: error.message });
        }
    };
      
    useEffect(() => {
        getProduct();

        const timeoutId = setTimeout(() => {
            if (!document.body.classList.contains('loaded')) {
                window.location.reload();
            }
        }, 5000);

        return () => clearTimeout(timeoutId);
    }, []);


    const getRandomSubset = (array, size) => {
        const shuffled = array.sort(() => 0.5 - Math.random());
        return shuffled.slice(0, size);
    };


  return (
    <div class="page-wrapper">
    <h1 class="d-none">Jampani Pickles</h1>
  
    <Header />  

    <main class="main">
        <div class="intro-section">
            <div class="swiper-container swiper-theme nav-inner pg-inner animation-slider pg-xxl-hide pg-show nav-xxl-show nav-hide"
                data-swiper-options="{
                'slidesPerView': 1,
                'autoplay': {
                    'delay': 4000,
                    'disableOnInteraction': false
                }
            }">
                <div class="swiper-wrapper row gutter-no cols-1">
                
                     <div class="swiper-slide banner banner-fixed intro-slide intro-slide3"
                        style={{ backgroundImage: 'url(assets/images/demos/demo2/slides/slide-3.jpg)', backgroundColor: '#d0cfcb'}}>
                        <div class="container">
                            <figure class="slide-image floating-item slide-animate" data-animation-options="{
                                'name': 'fadeInRightShorter', 'duration': '1s'
                            }" data-options="{'relativeInput':true,'clipRelativeInput':true,'invertX':true,'invertY':true}"
                                data-child-depth="0.2">
                                <img src="assets/images/demos/demo2/slides/man.png" alt="Ski" width="527"
                                    height="481" />
                            </figure>
                            <div class="banner-content y-50">
                                <h5 class="banner-subtitle text-uppercase font-weight-bold slide-animate"
                                    data-animation-options="{
                                    'name': 'fadeInRightShorter', 'duration': '1s'
                                }">Top Monthly Seller</h5>
                                <h4 class="banner-title ls-25 slide-animate" data-animation-options="{
                                    'name': 'fadeInRightShorter', 'duration': '1s'
                                }">Indulge in the authentic taste of   <span class="text-primary">Andhra</span> Flavours</h4>
                                <p class="font-weight-normal text-dark slide-animate" data-animation-options="{
                                    'name': 'fadeInRightShorter', 'duration': '1s'
                                }">with our collection of Homemade Pickles</p>
                                <a href="/"
                                    class="btn btn-dark btn-outline btn-rounded btn-icon-right slide-animate"
                                    data-animation-options="{
                                    'name': 'fadeInRightShorter', 'duration': '1s'
                                }">Shop Now<i class="w-icon-long-arrow-right"></i></a>
                            </div>
                         
                        </div>
                  
                    </div>
                   
                    
                    <div class="swiper-slide banner banner-fixed intro-slide intro-slide1"
                        style={{backgroundImage: 'url(assets/images/demos/demo2/slides/slide-1.jpg)', backgroundColor: '#f1f0f0'}}>
                        <div class="container">
                            <figure class="slide-image floating-item slide-animate" data-animation-options="{
                                'name': 'fadeInDownShorter', 'duration': '1s'
                            }" data-options="{'relativeInput':true,'clipRelativeInput':true,'invertX':true,'invertY':true}"
                                data-child-depth="0.2">
                                <img src="assets/images/demos/demo2/slides/ski.png" alt="Ski" width="729"
                                    height="570" />
                            </figure>
                            <div class="banner-content text-right y-50 ml-auto">
                                <h5 class="banner-subtitle text-uppercase font-weight-bold mb-2 slide-animate"
                                    data-animation-options="{
                                    'name': 'fadeInUpShorter', 'duration': '1s'
                                }">Deals of Taste</h5>
                                <h3 class="banner-title ls-25 mb-6 slide-animate" data-animation-options="{
                                    'name': 'fadeInUpShorter', 'duration': '1s'
                                }">One-stop   <span class="text-primary">Destination</span> 
                                    for buying the best Pickles online
                                </h3>
                                <a href="/"
                                    class="btn btn-dark btn-outline btn-rounded btn-icon-right slide-animate"
                                    data-animation-options="{
                                    'name': 'fadeInUpShorter', 'duration': '1s'
                                }">
                                    Shop Now<i class="w-icon-long-arrow-right"></i></a>
                            </div>
                           
                        </div>
                      
                    </div>
                

                    <div class="swiper-slide banner banner-fixed intro-slide intro-slide2"
                        style={{backgroundImage: 'url(assets/images/demos/demo2/slides/slide-2.jpg)', backgroundColor: '#d9ddd9'}}>
                        <div class="container">
                            <figure class="slide-image floating-item slide-animate" data-animation-options="{
                                'name': 'fadeInUpShorter', 'duration': '1s'
                            }" data-options="{'relativeInput':true,'clipRelativeInput':true,'invertX':true,'invertY':true}"
                                data-child-depth="0.2">
                                <img src="assets/images/demos/demo2/slides/woman.png" alt="Ski" width="865"
                                    height="732" />
                            </figure>
                            <div class="banner-content y-50">
                                <h5 class="banner-subtitle text-uppercase font-weight-bold mb-2 slide-animate"
                                    data-animation-options="{
                                    'name': 'fadeInRightShorter', 'duration': '1s', 'delay': '.5s'
                                }">Change your Daily Bland Meals</h5>
                                <h3 class="banner-title ls-25 mb-2 text-uppercase lh-1 slide-animate"
                                    data-animation-options="{
                                    'name': 'fadeInRightShorter', 'duration': '1s', 'delay': '.7s'
                                }">Explore a wide range of   </h3>
                                <div class="banner-price-info font-weight-bold text-dark ls-25 slide-animate"
                                    data-animation-options="{
                                    'name': 'fadeInRightShorter', 'duration': '1s', 'delay': '.9s'
                                }">flavorful
                                    <span class="text-primary font-weight-bolder text-uppercase ls-normal">and authentic pickles</span></div>
                                <p class="font-weight-normal text-default ls-25 slide-animate"
                                    data-animation-options="{
                                    'name': 'fadeInRightShorter', 'duration': '1s', 'delay': '1.1s'
                                }">to elevate your meals.</p>
                                <a href="/"
                                    class="btn btn-dark btn-outline btn-rounded btn-icon-right slide-animate"
                                    data-animation-options="{
                                    'name': 'fadeInRightShorter', 'duration': '1s', 'delay': '1.3s'
                                }">
                                    Shop Now<i class="w-icon-long-arrow-right"></i></a>
                            </div>
                       
                        </div>
                        
                    </div>
                    

                   
                </div>
                <div class="swiper-pagination"></div>
                <button class="swiper-button-next"></button>
                <button class="swiper-button-prev"></button>
            </div>
        </div>
        

        <div class="container">
            <div class="swiper-container swiper-theme icon-box-wrapper appear-animate br-sm mt-6 mb-10"
                data-swiper-options="{
                'loop': true,
                'slidesPerView': 1,
                'autoplay': {
                    'delay': 4000,
                    'disableOnInteraction': false
                },
                'breakpoints': {
                    '576': {
                        'slidesPerView': 2
                    },
                    '768': {
                        'slidesPerView': 3
                    },
                    '1200': {
                        'slidesPerView': 4
                    }
                }
            }">
                <div class="swiper-wrapper row cols-md-4 cols-sm-3 cols-1">
                    <div class="swiper-slide icon-box icon-box-side text-dark">
                        <span class="icon-box-icon icon-shipping">
                            <i class="w-icon-truck"></i>
                        </span>
                        <div class="icon-box-content">
                            <h4 class="icon-box-title">Free Shipping</h4>
                            <p class="text-default">Pan India & Order above ₹ 999.00</p>
                        </div>
                    </div>
                    <div class="swiper-slide icon-box icon-box-side text-dark">
                        <span class="icon-box-icon icon-payment">
                            <i class="w-icon-bag"></i>
                        </span>
                        <div class="icon-box-content">
                            <h4 class="icon-box-title">Customer Support</h4>
                            <p class="text-default">We're here to serve you by 24/7</p>
                        </div>
                    </div>
                    <div class="swiper-slide icon-box icon-box-side text-dark icon-box-money">
                        <span class="icon-box-icon icon-money">
                            <i class="w-icon-money"></i>
                        </span>
                        <div class="icon-box-content">
                            <h4 class="icon-box-title">International Shipping</h4>
                            <p class="text-default">We Accept Payments via Paypal</p>
                        </div>
                    </div>
                    <div class="swiper-slide icon-box icon-box-side text-dark icon-box-chat">
                        <span class="icon-box-icon icon-chat">
                            <i class="w-icon-chat"></i>
                        </span>
                        <div class="icon-box-content">
                            <h4 class="icon-box-title">Secure Payment</h4>
                            <p class="text-default">100% Secure Payment with Razorpay</p>
                        </div>
                    </div>
                </div>
            </div>
          
            
            <div class="tab tab-with-title tab-nav-boxed appear-animate">
                <h2 class="title">Trending Products</h2>
                <ul class="nav nav-tabs" role="tablist">
                    <li class="nav-item">
                        <a class="nav-link active" href="#tab-1">Veg Pickles</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="#tab-2">Non-Veg Pickles</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="#tab-3">Ghee Sweets</a>
                    </li>
                </ul>
            </div>
            
            
            <div class="tab-content appear-animate">
                <div class="tab-pane active" id="tab-1">
                    <div class="swiper-container swiper-theme product-deals-wrapper appear-animate mb-7"
                data-swiper-options="{
                'spaceBetween': 20,
                'slidesPerView': 2,
                'breakpoints': {
                    '576': {
                        'slidesPerView': 3
                    },
                    '768': {
                        'slidesPerView': 4
                    },
                    '992': {
                        'slidesPerView': 5
                    }
                }
            }">
                <div class="swiper-wrapper row cols-lg-5 cols-md-4 cols-2">
                   
                    
                    
                {productData && productData.data && productData.data.filter(products => products.category === "Veg Pickles").map((product, index)=>( 
                    
                    
                    <div class="swiper-slide product-wrap">
                        <div class="product text-center">
                            <figure class="product-media">
                                <a href={`/product?sku=${product.product_code}`}>
                                    <img src={`https://demo.sninfoserv.com/api/products/images/${product.images[0].image}`} alt={product.product} width="300"  height="300" />
                                </a>
                                <div class="product-action-vertical">
                                    <a href={`/product?sku=${product.product_code}`} class="btn-product-icon  w-icon-cart"
                                        title="Add to cart"></a>
                                    <a href="/" class="btn-product-icon btn-wishlist w-icon-heart"
                                        title="Add to wishlist"></a>
                                    <a href="/" class="btn-product-icon btn-quickview w-icon-search"
                                        title="Quickview"></a>
                                    <a href="/" class="btn-product-icon btn-compare w-icon-compare"
                                        title="Add to Compare"></a>
                                </div>
                            </figure>
                            <div class="product-details">
                                <h4 class="product-name"><a href={`/product?sku=${product.product_code}`}>{product.product} - {product.variations[0].weight}g</a></h4>
                                <div class="product-price">
                                    <ins class="new-price">₹ {Number(product.variations[0].price).toFixed(2)}</ins><del class="old-price">₹ {(Number(product.variations[0].price)+Math.floor(Math.random() * (11- 76 + 1)) + 11).toFixed(2)}</del>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    
                ))}     
                    
                    
                    
                </div>
            <div class="swiper-pagination"></div>
            </div>
            </div>


            <div class="tab-pane" id="tab-2">
            <div class="swiper-container swiper-theme product-deals-wrapper appear-animate mb-7"
                data-swiper-options="{
                'spaceBetween': 20,
                'slidesPerView': 2,
                'breakpoints': {
                    '576': {
                        'slidesPerView': 3
                    },
                    '768': {
                        'slidesPerView': 4
                    },
                    '992': {
                        'slidesPerView': 5
                    }
                }
            }">
                <div class="swiper-wrapper row cols-lg-5 cols-md-4 cols-2">
                
                
                    
                {productData && productData.data && productData.data.filter(products => products.category === "Non Veg Pickles").map((product, index)=>( 
                    
                    
                    <div class="swiper-slide product-wrap">
                        <div class="product text-center">
                            <figure class="product-media">
                                <a href={`/product?sku=${product.product_code}`}>
                                    <img src={`https://demo.sninfoserv.com/api/products/images/${product.images[0].image}`} alt={product.product} width="300"  height="300" />
                                </a>
                                <div class="product-action-vertical">
                                    <a href={`/product?sku=${product.product_code}`} class="btn-product-icon  w-icon-cart"
                                        title="Add to cart"></a>
                                    <a href="/" class="btn-product-icon btn-wishlist w-icon-heart"
                                        title="Add to wishlist"></a>
                                    <a href="/" class="btn-product-icon btn-quickview w-icon-search"
                                        title="Quickview"></a>
                                    <a href="/" class="btn-product-icon btn-compare w-icon-compare"
                                        title="Add to Compare"></a>
                                </div>
                            </figure>
                            <div class="product-details">
                                <h4 class="product-name"><a href={`/product?sku=${product.product_code}`}>{product.product} - {product.variations[0].weight}g</a></h4>
                                <div class="product-price">
                                    <ins class="new-price">₹ {Number(product.variations[0].price).toFixed(2)}</ins><del class="old-price">₹ {(Number(product.variations[0].price)+Math.floor(Math.random() * (11- 76 + 1)) + 11).toFixed(2)}</del>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    
                ))}  
                    
                    
                </div>
                <div class="swiper-pagination"></div>
            </div>
                </div>
                <div class="tab-pane" id="tab-3">
                    <div class="swiper-container swiper-theme product-deals-wrapper appear-animate mb-7"
                data-swiper-options="{
                'spaceBetween': 20,
                'slidesPerView': 2,
                'breakpoints': {
                    '576': {
                        'slidesPerView': 3
                    },
                    '768': {
                        'slidesPerView': 4
                    },
                    '992': {
                        'slidesPerView': 5
                    }
                }
            }">
                <div class="swiper-wrapper row cols-lg-5 cols-md-4 cols-2">
                
                
                {productData && productData.data && productData.data.filter(products => products.category === "Ghee Sweets").map((product, index)=>( 
                    
                    
                    <div class="swiper-slide product-wrap">
                        <div class="product text-center">
                            <figure class="product-media">
                                <a href={`/product?sku=${product.product_code}`}>
                                    <img src={`https://demo.sninfoserv.com/api/products/images/${product.images[0].image}`} alt={product.product} width="300"  height="300" />
                                </a>
                                <div class="product-action-vertical">
                                    <a href={`/product?sku=${product.product_code}`} class="btn-product-icon  w-icon-cart"
                                        title="Add to cart"></a>
                                    <a href="/" class="btn-product-icon btn-wishlist w-icon-heart"
                                        title="Add to wishlist"></a>
                                    <a href="/" class="btn-product-icon btn-quickview w-icon-search"
                                        title="Quickview"></a>
                                    <a href="/" class="btn-product-icon btn-compare w-icon-compare"
                                        title="Add to Compare"></a>
                                </div>
                            </figure>
                            <div class="product-details">
                                <h4 class="product-name"><a href={`/product?sku=${product.product_code}`}>{product.product} - {product.variations[0].weight}g</a></h4>
                                <div class="product-price">
                                    <ins class="new-price">₹ {Number(product.variations[0].price).toFixed(2)}</ins><del class="old-price">₹ {(Number(product.variations[0].price)+Math.floor(Math.random() * (11- 76 + 1)) + 11).toFixed(2)}</del>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    
                ))}
                
                    
                </div>
                <div class="swiper-pagination"></div>
            </div>
                </div>
            </div>
            

            
            
            <div class="banner-product-wrapper appear-animate row mb-8">
               
                <div class="col-xl-12 col6 col-md-12 mb-4">
                    <div class="banner br-sm mb-4" style={{backgroundImage: 'url(assets/images/demos/demo2/banners/1.jpg)',
                        backgroundColor: '#EEF0EF'}}>
                        <div class="banner-content d-block d-lg-flex align-items-center">
                            <div class="content-left mr-auto">
                                <h5 class="banner-subtitle font-weight-normal text-capitalize texyt-dark ls-25 mb-0">
                                    Free Shipping <br></br><strong class="text-uppercase text-secondary">PAN INDIA</strong>
                                </h5>
                                <br></br><h3 class="banner-title text-capitalize ls-25">on orders above ₹999</h3>
                                
                            </div>
                            <a href="/" class="content-left btn btn-dark btn btn-outline 
                                btn-rounded btn-icon-right mt-4 mt-lg-0">Shop Now<i
                                    class="w-icon-long-arrow-right"></i>
                            </a>
                        </div>
                    </div>
                    
                    <div class="title-link-wrapper mb-3 appear-animate">
                        <h2 class="title title-deals mb-1">Combo Offer</h2>
                        <a href="/view-products?main-category=Combos" class="font-weight-bold ls-25">View All<i class="w-icon-long-arrow-right"></i></a>
                    </div>
                    
                    <div class="swiper-container swiper-theme" data-swiper-options="{
                        'spaceBetween': 20,
                        'slidesPerView': 2,
                        'breakpoints': {
                            '576': {
                                'slidesPerView': 3
                            },
                            '768': {
                                'slidesPerView': 2
                            },
                            '992': {
                                'slidesPerView': 3
                            },
                            '1200': {
                                'slidesPerView': 4
                            }
                        }
                    }">
                        <div class="swiper-wrapper row cols-xl-4 cols-lg-3">
                            <div class="swiper-slide product-wrap">
                                <div class="product text-center">
                                    <figure class="product-media">
                                        <a href="/">
                                            <img src="assets/products/SWEETS.jpg" alt="Product"
                                                width="300" height="338" />
                                            
                                        </a>
                                        <div class="product-action-vertical">
                                            <a href="#" class="btn-product-icon  w-icon-cart"
                                                title="Add to cart"></a>
                                            <a href="#" class="btn-product-icon btn-wishlist w-icon-heart"
                                                title="Add to wishlist"></a>
                                            <a href="#" class="btn-product-icon btn-quickview w-icon-search"
                                                title="Quickview"></a>
                                            <a href="#" class="btn-product-icon btn-compare w-icon-compare"
                                                title="Add to Compare"></a>
                                        </div>
                                    </figure>
                                    <div class="product-details">
                                        <h4 class="product-name"><a href="/">Sweets Combo</a>
                                        </h4>
                                        <div class="product-price">
                                            <ins class="new-price">₹1099</ins>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="swiper-slide product-wrap">
                                <div class="product text-center">
                                    <figure class="product-media">
                                        <a href="/">
                                            <img src="assets/products/MIXED SNACKS.jpg" alt="Product"
                                                width="300" height="338" />
                                            
                                        </a>
                                        <div class="product-action-vertical">
                                            <a href="#" class="btn-product-icon  w-icon-cart"
                                                title="Add to cart"></a>
                                            <a href="#" class="btn-product-icon btn-wishlist w-icon-heart"
                                                title="Add to wishlist"></a>
                                            <a href="#" class="btn-product-icon btn-quickview w-icon-search"
                                                title="Quickview"></a>
                                            <a href="#" class="btn-product-icon btn-compare w-icon-compare"
                                                title="Add to Compare"></a>
                                        </div>
                                    </figure>
                                    <div class="product-details">
                                        <h4 class="product-name"><a href="/">MIXED SNACKS COMBO</a>
                                        </h4>
                                       
                                        <div class="product-price">
                                            <ins class="new-price">₹999</ins>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="swiper-slide product-wrap">
                                <div class="product text-center">
                                    <figure class="product-media">
                                        <a href="/">
                                            <img src="assets//products/namkeen banner.jpg" alt="Product"
                                                width="300" height="338" />
                                        </a>
                                        <div class="product-action-vertical">
                                            <a href="#" class="btn-product-icon  w-icon-cart"
                                                title="Add to cart"></a>
                                            <a href="#" class="btn-product-icon btn-wishlist w-icon-heart"
                                                title="Add to wishlist"></a>
                                            <a href="#" class="btn-product-icon btn-quickview w-icon-search"
                                                title="Quickview"></a>
                                            <a href="#" class="btn-product-icon btn-compare w-icon-compare"
                                                title="Add to Compare"></a>
                                        </div>
                                        <div class="product-label-group">
                                            <label class="product-label label-new">New</label>
                                        </div>
                                    </figure>
                                    <div class="product-details">
                                        <h4 class="product-name"><a href="/">Namkeen Combo</a>
                                        </h4>
                                        
                                        <div class="product-price">
                                            <ins class="new-price">₹999</ins>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="swiper-slide product-wrap">
                                <div class="product text-center">
                                    <figure class="product-media">
                                        <a href="/">
                                            <img src="assets/products/celebrity.jpg" alt="Product"
                                                width="300" height="338" />
                                        </a>
                                        <div class="product-action-vertical">
                                            <a href="#" class="btn-product-icon  w-icon-cart"
                                                title="Add to cart"></a>
                                            <a href="#" class="btn-product-icon btn-wishlist w-icon-heart"
                                                title="Add to wishlist"></a>
                                            <a href="#" class="btn-product-icon btn-quickview w-icon-search"
                                                title="Quickview"></a>
                                            <a href="#" class="btn-product-icon btn-compare w-icon-compare"
                                                title="Add to Compare"></a>
                                        </div>
                                    </figure>
                                    <div class="product-details">
                                        <h4 class="product-name"><a href="/">Celebrity Combo</a>
                                        </h4>
                                         
                                        <div class="product-price">
                                            <ins class="new-price">₹2222</ins>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="swiper-pagination"></div>
                    </div>
                </div>
            </div>
            
            
            
            
             <div class="sale-banner banner br-sm appear-animate">
                <div class="banner-content">
                    <h6
                        class="content-left banner-subtitle text-uppercase mb-8 mb-md-0 mr-0 mr-md-4 text-secondary ls-25">
                         
                        <span class="text-dark font-weight-bold lh-1 ls-normal">International  Courier Charges To 
                            </span> UK</h6>
                    <div class="content-right">
                        <h3 class="banner-title text-uppercase font-weight-normal mb-4 mb-md-0 ls-25 text-white">
                            <span>
                                <strong class="mr-10 pr-lg-10">400/- per Kg above 16kgs weight</strong>
                               
                                <strong class="mr-10 pr-lg-10">400/- per Kg above 16kgs weight</strong>
                                
                                <strong class="mr-10 pr-lg-10">400/- per Kg above 16kgs weight</strong>
                            </span>
                        </h3>
                        <a href="#" class="btn btn-white btn-rounded">Shop Now
                            <i class="w-icon-long-arrow-right"></i>
                        </a>
                    </div>
                </div>
            </div>
            
            
            
             <div class="title-link-wrapper mb-3 appear-animate">
                <h2 class="title title-deals mb-1">Jampani Signature Products</h2>
               
                <a href="/" class="font-weight-bold ls-25">View All<i
                        class="w-icon-long-arrow-right"></i></a>
            </div>
            <div class="row grid-type products">

                        <div class="product-wrap lg-item">
                            <div class="product text-center">
                                <figure class="product-media">
                                    <a href="/">
                                        <img src="assets/products/signature.jpg" alt="Product" width="300" height="338" />
                                    </a>
                                   
                                </figure>
                                <div class="product-details">
                                    <h4 class="product-name"><a href="/"></a>
                                    </h4>
                                  
                                    <div class="product-price">
                                        <ins class="new-price"></ins>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {productData && productData.data && getRandomSubset(productData.data, 6).map((product, index) => (
                        
                        <div class="product-wrap sm-item">
                            <div class="product text-center">
                                <figure class="product-media">
                                    <a href={`/product?sku=${product.product_code}`}>
                                        <img src={`https://demo.sninfoserv.com/api/products/images/${product.images[0].image}`} alt={product.product} width="300" height="338" />
                                        
                                    </a>
                                    <div class="product-action-vertical">
                                        <a href={`/product?sku=${product.product_code}`} class="btn-product-icon  w-icon-cart"
                                            title="Add to cart"></a>
                                        <a href="#" class="btn-product-icon btn-wishlist w-icon-heart"
                                            title="Add to wishlist"></a>
                                        <a href="#" class="btn-product-icon btn-quickview w-icon-search"
                                            title="Quickview"></a>
                                        <a href="#" class="btn-product-icon btn-compare w-icon-compare"
                                            title="Add to Compare"></a>
                                    </div>
                                    <div class="product-label-group">
                                        <label class="product-label label-new">New</label>
                                    </div>
                                </figure>
                                <div class="product-details">
                                    <h4 class="product-name"><a href={`/product?sku=${product.product_code}`}>{product.product}</a></h4>
                                     
                                    <div class="product-price">
                                        <ins class="new-price">₹ {Number(product.variations[0].price).toFixed(2)}</ins>
                                    </div>
                                </div>
                            </div>
                        </div>

                        ))}
                        
                       
                        
                    </div>

            
            
            <div class="row category-wrapper electronics-cosmetics appear-animate mb-7">
                <div class="col-md-6 mb-4">
                    <div class="banner banner-fixed br-sm">
                        <figure>
                            <img src="assets/images/demos/demo2/categories/1-1.jpg" alt="Category Banner"
                                width="640" height="200" style={{backgroundColor: '#25282D'}} />
                        </figure>
                        <div class="banner-content y-50">
                            <h3 class="banner-title text-white ls-25 mb-0">MOUTH WATERING</h3>
                            <div class="banner-price-info text-white font-weight-bold text-uppercase mb-1">PICKLES
                               
                            </div>
                            <hr class="banner-divider bg-white" />
                            <a href="/"
                                class="btn btn-white btn-link btn-underline btn-icon-right">
                                Shop Now<i class="w-icon-long-arrow-right"></i></a>
                        </div>
                    </div>
                </div>
                
                
                <div class="col-md-6 mb-4">
                    <div class="banner banner-fixed br-sm">
                        <figure>
                            <img src="assets/images/demos/demo2/categories/1-2.jpg" alt="Category Banner"
                                width="640" height="200" style={{backgroundColor: '#eeedec'}} />
                        </figure>
                        <div class="banner-content y-50">
                            <h3 class="banner-title ls-25 text-capitalize mb-0">TASTY PICKLES</h3>
                            <div class="banner-price-info font-weight-bold text-uppercase mb-1">Varities of collections
                                
                            </div>
                            <hr class="banner-divider bg-dark" />
                            <a href="/"
                                class="btn btn-dark btn-link btn-underline btn-icon-right">
                                Shop Now<i class="w-icon-long-arrow-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>
           
            
             <div class="title-link-wrapper mb-3 appear-animate">
                <h2 class="title title-deals mb-1">Savouries</h2>
               
                <a href="/view-products?main-category=Savouries" class="font-weight-bold ls-25">View All<i
                        class="w-icon-long-arrow-right"></i></a>
            </div>
            
            <div class="swiper-container swiper-theme product-deals-wrapper appear-animate mb-7"
                data-swiper-options="{
                'spaceBetween': 20,
                'slidesPerView': 2,
                'breakpoints': {
                    '576': {
                        'slidesPerView': 3
                    },
                    '768': {
                        'slidesPerView': 4
                    },
                    '992': {
                        'slidesPerView': 5
                    }
                }
            }">
                <div class="swiper-wrapper row cols-lg-5 cols-md-4 cols-2">

                {productData && productData.data && productData.data.filter(products => products.product_type === "Savouries").map((product, index)=>( 
                    <div class="swiper-slide product-wrap">
                        <div class="product text-center">
                            <figure class="product-media">
                                <a href={`/product?sku=${product.product_code}`}>
                                    <img src={`https://demo.sninfoserv.com/api/products/images/${product.images[0].image}`} alt={product.product}
                                        width="300" height="338" />
                                    <img src={`https://demo.sninfoserv.com/api/products/images/${product.images[0].image}`} alt={product.product}
                                        width="300" height="338" />
                                </a>
                                <div class="product-action-vertical">
                                    <a href={`/product?sku=${product.product_code}`} class="btn-product-icon  w-icon-cart"
                                        title="Add to cart"></a>
                                    <a href="#" class="btn-product-icon btn-wishlist w-icon-heart"
                                        title="Add to wishlist"></a>
                                    <a href="#" class="btn-product-icon btn-quickview w-icon-search"
                                        title="Quickview"></a>
                                    <a href="#" class="btn-product-icon btn-compare w-icon-compare"
                                        title="Add to Compare"></a>
                                </div>
                                <div class="product-label-group">
                                    <label class="product-label label-new">New</label>
                                </div>
                            </figure>
                            <div class="product-details">
                                <h4 class="product-name"><a href={`/product?sku=${product.product_code}`}>{product.product}</a></h4>
                                 
                                <div class="product-price">
                                    <ins class="new-price">₹ {Number(product.variations[0].price).toFixed(2)}</ins>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                ))}
                   
                    
                    
                <div class="swiper-pagination"></div>
            </div>

           


            
           

            <div class="row category-wrapper sports-fashion mb-8 appear-animate">
                <div class="col-md-6 mb-4">
                    <div class="banner banner-fixed br-sm">
                        <figure>
                            <img src="assets/images/demos/demo2/categories/2-1.jpg" alt="Category Banner"
                                width="640" height="200" style={{backgroundColor: '#EAEAEA'}} />
                        </figure>
                        <div class="banner-content y-50 text-right">
                            <h5 class="banner-subtitle text-uppercase font-weight-bold">EXPLORE OUR </h5>
                            <h3 class="banner-title text-capitalize ls-25">NEW PRODUCTS</h3>
                            <hr class="banner-divider bg-dark ml-auto mb-3" />
                            
                        </div>
                    </div>
                </div>
                <div class="col-md-6 mb-4">
                    <div class="banner banner-fixed br-sm">
                        <figure>
                            <img src="assets/images/demos/demo2/categories/2-2.jpg" alt="Category Banner"
                                width="640" height="200" style={{backgroundColor: '#181411'}} />
                        </figure>
                        <div class="banner-content y-50">
                            <h5 class="banner-subtitle text-uppercase font-weight-normal text-white">FRYUMS COLLECTIONS 
                            </h5>
                            <h3 class="banner-title text-white ls-25">Available</h3>
                            <hr class="banner-divider bg-white" />
                            
                        </div>
                    </div>
                </div>
            </div>
           


            <h2 class="title text-left pt-1 appear-animate">Foodies Feedback</h2>
            <p class="text-default">Checkout what others say about Jampani</p>
            <div class="swiper-container swiper-theme post-wrapper mb-10 mb-lg-5 appear-animate"
                data-swiper-options="{
                'spaceBetween': 20,
                'slidesPerView': 1,
                'breakpoints': {
                    '576': {
                        'slidesPerView': 2
                    },
                    '768': {
                        'slidesPerView': 3
                    },
                    '992': {
                        'slidesPerView': 4
                    }
                }
            }">
                <div class="swiper-wrapper row cols-lg-4 cols-md-3 cols-sm-2 cols-1">
                    <div class="swiper-slide post">
                        <figure class="post-media br-sm">
                          <iframe width="100%" height="auto" src="https://www.youtube.com/embed/dKbjEQcZciY?si=dw1zmuRO23sWL6b6&amp;controls=0&amp;autoplay=1&amp;mute=1" title="YouTube video player" frameborder="0" allow=" autoplay;" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        </figure>
                    </div>
                    <div class="swiper-slide post">
                        <figure class="post-media br-sm">
                           <iframe width="100%" height="auto" src="https://www.youtube.com/embed/tKaekZXvmoU?si=ZWBVyU9RKyGiV56Y&amp;controls=0&amp;autoplay=1&amp;mute=1" title="YouTube video player" frameborder="0" allow="autoplay; " referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        </figure>
                    </div>
                     <div class="swiper-slide post">
                        <figure class="post-media br-sm">
                           <iframe width="100%" height="auto" src="https://www.youtube.com/embed/UTyH9XuRv3g?si=wyYHFLV4A4ZLq1DA&amp;controls=0&amp;autoplay=1&amp;mute=1" title="YouTube video player" frameborder="0" allow="autoplay; " referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        </figure>
                    </div>
                     <div class="swiper-slide post">
                        <figure class="post-media br-sm">
                           <iframe width="100%" height="auto" src="https://www.youtube.com/embed/L0s2sqJ8WtU?si=IRncimknn2mep1m4&amp;controls=0&amp;autoplay=1&amp;mute=1" title="YouTube video player" frameborder="0" allow="autoplay; " referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        </figure>
                    </div>
                </div>
                <div class="swiper-pagination"></div>
            </div>
          </div>
        </div>
        
    </main>
   
    <Footer />
    
    
</div>
  );
}

export default App;
