import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ReactSession } from 'react-client-session';
import { useNavigate, useParams } from 'react-router-dom';
import Header from './header';
import Footer from './footer';
ReactSession.setStoreType("localStorage");

function App() {

    const [orderData, setOrderData] = useState('');
    const [orderDetails, setOrderDetails] = useState('');
    const [billingData, setBillingData] = useState('');
    const [shippingData, setShippingData] = useState('');
    const [orderId, setOrderId] = useState('');
    const [error, setError] = useState('');
    const [password1, setPassword1] = useState('');
    const [password2, setPassword2] = useState('');
    const [passowordError, setPasswordError] = useState(null);
    const navigate = useNavigate();
    const userId = sessionStorage.getItem('jmpiUserId');

    const extractProductId = () => {
        const url = window.location.href; 
        const queryString = url.split('?')[1] || ''; 
        const params = new URLSearchParams(queryString);
        const product = params.get('id');
        if (product) {
          setOrderId(product);    
        }
    };

    useEffect(() => {
        extractProductId();
    }, [orderId, orderData]);


    const getOrderDetails = async () => {
        try{
          const response = await axios.post('https://demo.sninfoserv.com/api/orders/get-order/', {orderId: orderId});
          console.log(response.data);
          if(response.data.status === 'success'){
            setOrderData(response.data.orderData);
            setOrderDetails(response.data.orderDetails);
            setBillingData(response.data.billingData);
            setShippingData(response.data.shippingData);
          }
        }catch (error) {
        setError({ error: error.message });
        }
    };

    useEffect(() => {
        getOrderDetails();
    },[orderId]);

    //console.log(billingData);

    const createAccount = async () => {
       if( password1 !== password2){
        setPasswordError('Passwords are not matching!');
       }else{
        setPasswordError(null);
        const accData = {
            fname: billingData && billingData.fName,
            lname: billingData && billingData.lName,
            adrs1: billingData && billingData.adrs1,
            adrs2: billingData && billingData.adrs1,
            town: billingData && billingData.town,
            state: billingData && billingData.state,
            country: billingData && billingData.country,
            zip: billingData && billingData.zip,
            phone: billingData && billingData.phone,
            email: billingData && billingData.email,
            pass: password1,
            orderId: orderId
        }
        try{
          const response = await axios.post('https://demo.sninfoserv.com/api/customer/', accData);
          console.log(response.data);
          if(response.data.status === 'success'){
            navigate('/');
          }else{
            setPasswordError(response.data.message);
          }
        }catch (error) {
          setError({ error: error.message });
        }
    }

    };
    return (
        <div class="page-wrapper">
          <h1 class="d-none">Jampani Pickles</h1>
          <Header />  
          <main class="main order">
           
            <nav class="breadcrumb-nav">
                <div class="container">
                    <ul class="breadcrumb shop-breadcrumb bb-no">
                        <li class="passed">Cart</li>
                        <li class="passed">Checkout</li>
                        <li class="active">Order Complete</li>
                    </ul>
                </div>
            </nav>
            
            <div class="page-content mb-10 pb-2">
                <div class="container">
                <div style={{background: '#eceeec', padding: '20px'}}>
                    <div align='center'>
                        <img src='assets/img/sucsess.gif' width="200px" />
                    </div>
                    <h3 class="order-success text-center font-weight-bolder text-dark mb-4 mt-4">
                        {/* <i class="fas fa-check pr-2 text-success"></i>  */}
                        Thank you. Your order has been received.
                    </h3>
 
                  {userId ? <></> : 
                  
                    <div align="center">
                    <h6 class="text-primary">Do you want to sign up with your billing details? Just enter the password to signup.</h6>
                    <div class='row' align="center" style={{textAlign: 'center'}}>
                        <div class="col-12 col-lg-3 col-md-3 col-xl-3 col-xxl-3 mb-2"></div>
                        <div class="col-12 col-lg-3 col-md-3 col-xl-3 col-xxl-3 mb-2">
                          <div class="form-group">
                            <label>Enter Password</label>
                            <input type="password" class="form-control form-control-md" style={{background:'#FFF'}} value={password1} onChange={(e) => setPassword1(e.target.value)}  />
                          </div>
                        </div>

                        <div class="col-12 col-lg-3 col-md-3 col-xl-3 col-xxl-3 mb-2">
                          <div class="form-group">
                            <label>Re-Enter Password</label>
                            <input type="password" class="form-control form-control-md" style={{background:'#FFF'}} value={password2}  onChange={(e) => setPassword2(e.target.value)} />
                          </div>
                        </div>
                        <div class="col-12 col-lg-3 col-md-3 col-xl-3 col-xxl-3 mb-2"></div>
                    </div>
                    {passowordError !== null && <p class="text-danger">{passowordError}</p>}
                    <button class='btn btn-primary' onClick={createAccount}>Create Account</button>
                    </div>
                  }

                </div>
                    <div class="row mb-10 mt-10" style={{background: '#f9f9f9'}}>
                        <div class="col-12 col-lg-3 col-md-3 col-xl-3 col-xxl-3 box" >
                            <p class="text-gray fs-15">Order Number<h4>{orderData.length > 0 ? orderData[0].orderId : 'Loading...'}</h4></p>
                        </div>
                        <div class="col-12 col-lg-3 col-md-3 col-xl-3 col-xxl-3 box">
                            <p class="text-gray fs-15">Status<h4>{orderData.length > 0 ? orderData[0].orderStatus : 'Loading...'}</h4></p>
                        </div>
                        <div class="col-12 col-lg-3 col-md-3 col-xl-3 col-xxl-3 box">
                            <p class="text-gray fs-15">Date<h4>{orderData.length > 0 ? orderData[0].time : 'Loading...'}</h4></p>
                        </div>
                        <div class="col-12 col-lg-3 col-md-3 col-xl-3 col-xxl-3 box">
                            <p class="text-gray fs-15">Total<h4>₹ {orderData.length > 0 ? Number(orderData[0].grandTotal).toFixed(2) : 'Loading...'}</h4></p>
                        </div>
                    </div>
                    
                    
                    <div class="row">

                    <div class="col-12 col-lg-8 col-md-8 col-xl-8 col-xxl-8 mb-5" style={{background: '#f9f9f9', border: '5px solid #fff'}}>
                    <div style={{padding: '20px'}}>
                        <h4 class="title text-uppercase ls-25 mb-5">Order Details</h4>
                        <table class="order-table text-center" width="100%">
                            <thead>
                                <tr>
                                    <th class="text-dark text-left">Product</th>
                                    <th width="25%">Price</th>
                                    <th width="10%">Qty</th>
                                    <th width="30%" class="text-right">Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                            {orderDetails && orderDetails.length > 0 && orderDetails ? (
                                orderDetails.map(product => (
                                  <tr key={product.id}>
                                    <td class="text-left">{product.productName} - {product.productWeight}g</td>
                                    <td>₹ {Number(product.productPrice).toFixed(2)}</td>
                                    <td>{product.productQty}</td>
                                    <td className="text-right">₹ {Number(product.productPrice * product.productQty).toFixed(2)}</td>
                                  </tr>
                                 ))
                            ) : (
                                 <tr>
                                    <td colSpan="4">Loading</td>
                                 </tr>
                            )}
                               <tr>
                                  <td colSpan={4} style={{padding: '30px'}}> <hr></hr></td>
                               </tr>
                               
                          
                                <tr>
                                    <th colspan='3' class="text-right">Subtotal :</th>
                                    <td class="text-right">₹ {orderData.length > 0 ? Number(orderData[0].subTotal - orderData[0].gst).toFixed(2) : 'Loading...'}</td>
                                </tr>
                                <tr>
                                    <th colspan='3' class="text-right">Tax :</th>
                                    <td class="text-right">₹ {orderData.length > 0 ? Number(orderData[0].gst).toFixed(2) : 'Loading...'}</td>
                                </tr>
                                <tr>
                                    <th colspan='3' class="text-right">Shipping :</th>
                                    <td class="text-right">₹ {orderData.length > 0 ? Number(orderData[0].shippingCharge).toFixed(2) : 'Loading...'}</td>
                                </tr>
                                <tr>
                                    <th colspan='3' class="text-right">Discount :</th>
                                    <td class="text-right">- ₹ {orderData.length > 0 ? Number(orderData[0].discount).toFixed(2) : 'Loading...'}</td>
                                </tr>
                               
                                <tr class="total">
                                    <th colspan='3' class="border-no text-right">Grand Total :</th>
                                    <td class="border-no text-right">₹ {orderData.length > 0 ? Number(orderData[0].grandTotal).toFixed(2) : 'Loading...'}</td>
                                </tr>
                                </tbody>
                        </table>
                        </div>
                    </div>
                    <div class="col-12 col-lg-4 col-md-4 col-xl-4 col-xxl-4 mb-5" style={{background: '#f9f9f9', border: '5px solid #fff'}}>
                        <div style={{padding: '20px'}}>
                          <h4 class="title title-underline ls-25 font-weight-bold">Billing Address</h4>
                          {billingData && billingData ? (
                            <>
                             <p><b>{billingData.fName} {billingData.lName}</b><br />
                                {billingData.adrs1}, {billingData.adrs2} <br />
                                {billingData.town}, {billingData.state}<br />
                                {billingData.country} - {billingData.zip}
                             </p>
                             <p><i class="fa fa-phone fa-rotate-90"></i> {billingData.phone}<br />
                              <i class="fa fa-envelope"></i> {billingData.email}</p>
                            </>
                          ):(
                            <p>Loading...</p>
                          )}
                          <h4 class="title title-underline ls-25 font-weight-bold">Shipping Address</h4>
                          {shippingData && shippingData ? (
                            <>
                             <p><b>{shippingData.fName} {shippingData.lName}</b><br />
                                {shippingData.adrs1}, {shippingData.adrs2} <br />
                                {shippingData.town}, {shippingData.state}<br />
                                {shippingData.country} - {shippingData.zip}
                             </p>
                             <p><i class="fa fa-phone fa-rotate-90"></i> {shippingData.phone}<br />
                             <i class="fa fa-envelope"></i> {shippingData.email}</p>
                            </>
                          ):(
                            <p>Loading...</p>
                          )}
                       </div>
                    </div>
                    
                    </div>
                   
                  

                    <a href="/" class="btn btn-dark btn-rounded btn-icon-left btn-back mt-6"><i class="w-icon-long-arrow-left"></i>Shop Now</a>
                </div>
            </div>
           
        </main>
          <Footer />
        </div>
  );
}

export default App;