import React, { useEffect, useState } from 'react';
import { ReactSession } from 'react-client-session';
import { useNavigate, useParams } from 'react-router-dom';
ReactSession.setStoreType("localStorage");

function App() {
    
    const navigate = useNavigate();
    const [cartItems, setCartItems] = useState([]);
    const [cartTotal, setTotal] = useState(0);
    const userId = sessionStorage.getItem('jmpiUserId');

    const logout = () => {
        sessionStorage.removeItem('jmpiUserId');
        window.location = '/login';
    };

    const carts =() =>{
        const storedCartItems = JSON.parse(sessionStorage.getItem('cartItems')) || [];
        setCartItems(storedCartItems);
    };
    
    useEffect(() => {
        carts();
    }, [cartItems]);

    useEffect(() => {
        if (cartItems && cartItems.length > 0) {
          let sum = 0;
          cartItems.forEach(item => {
            sum += item.productPrice * item.productQty; 
          });
          setTotal(sum);
        }
    }, [cartItems]); 

    const removeFromCart = (productID, productWeights) => {
        const existingCartItems = JSON.parse(sessionStorage.getItem('cartItems')) || [];
        const updatedCartItems = existingCartItems.filter(
            item => !(item.productId === productID && item.productWeight === productWeights)
        );
        sessionStorage.setItem('cartItems', JSON.stringify(updatedCartItems));
    };

    const openMenu = () => {
        const body = document.body;
        if (body.classList.contains('mmenu')) {
            body.classList.remove('mmenu', 'mmenu-active');
        } else {
            body.classList.add('mmenu', 'mmenu-active');
        }
    };

    return (

        <header class="header">
        <div class="header-middle">
            <div class="container">
                <div class="header-left mr-md-4">
                    <a href="javascript:void(0)" class="mobile-menu-toggle  w-icon-hamburger" aria-label="menu-toggle" onClick={openMenu} >
                    </a>
                    <a href="/" class="logo ml-lg-0">
                        <img src="assets/images/logo.png" alt="logo" width="144" height="45" />
                    </a>
                    <nav class="main-nav">
                        <ul class="menu">
                            <li><a href="/">Home</a></li>
                            <li><a href="/">About</a></li>
                            <li><a href="/all-products">All Products</a></li>
                            <li><a href="/">Contact</a></li>
                        </ul>
                    </nav>
                </div>
                <div class="header-right ml-4">
                    <div class="header-call d-xs-show d-lg-flex align-items-center">
                        <a href="tel:+917416720111" class="w-icon-call"></a>
                        <div class="call-info d-xl-show">
                            <h4 class="chat font-weight-normal font-size-md text-normal ls-normal text-light mb-0">
                                 Customer Support :</h4>
                            <a href="tel:+917416720111" class="phone-number font-weight-bolder ls-50">+91 74167 20111</a>
                        </div>
                    </div>
                    <a class="wishlist label-down link d-xs-show" href="/">
                        <i class="w-icon-heart"></i>
                        <span class="wishlist-label d-lg-show">Wishlist</span>
                    </a>
                    <a class="compare label-down link d-xs-show" href="/">
                        <i class="w-icon-compare"></i>
                        <span class="compare-label d-lg-show">Compare</span>
                    </a>
                    <div class="dropdown cart-dropdown mr-0 mr-lg-2">
                        <div class="cart-overlay"></div>
                        <a href="/cart" class="cart-toggle label-down link">
                            <i class="w-icon-cart">
                                <span class="cart-count">{cartItems.length}</span>
                            </i>
                            <span class="cart-label">Cart</span>
                        </a>
                        
                        <div class="dropdown-box">
                            <div class="products" style={{ overflowX: 'hidden', overflowY: 'visible', paddingRight:'5px',  WebkitOverflowScrolling: 'touch', scrollbarWidth: 'none', msOverflowStyle: 'none'  }}>
                            {cartItems.map((item, index) => (
                                <div class="product product-cart">
                                    <div class="product-detail">
                                        <a href="/" class="product-name">{item.productName} {item.productWeight}g
                                          </a>
                                        <div class="price-box">
                                            <span class="product-quantity">{item.productQty}</span>
                                            <span class="product-price">₹ {Number(item.productPrice).toFixed(2)}</span>
                                        </div>
                                    </div>
                                    <figure class="product-media">
                                        <a href="/">
                                            <img src={`https://demo.sninfoserv.com/api/products/images/${item.productImage}`} alt={item.productName} height="84"
                                                width="94" />
                                        </a>
                                    </figure>
                                    <button class="btn btn-link btn-close" aria-label="button" onClick={() => removeFromCart(item.productId, item.productWeight)}>
                                        <i class="fas fa-times"></i>
                                    </button>
                                </div>
                            ))}
                               
                            </div>

                            <div class="cart-total">
                                <label>Subtotal:</label>
                                <span class="price">₹ {Number(cartTotal).toFixed(2)}</span>
                            </div>

                            <div class="cart-action">
                                <a href="/cart" class="btn btn-dark btn-outline btn-rounded">View Cart</a>
                                <a href="/checkout" class="btn btn-primary  btn-rounded">Checkout</a>
                            </div>
                        </div>
                       
                    </div>
                </div>
            </div>
        </div>

        <div class="header-bottom sticky-content fix-top sticky-header">
            <div class="container">
                <div class="inner-wrap">
                    <div class="header-left flex-1">
                        <div class="dropdown category-dropdown has-border" data-visible="true">
                            <a href="#" class="category-toggle" role="button" data-toggle="dropdown"
                                aria-haspopup="true" aria-expanded="true" data-display="static"
                                title="Browse Categories">
                                <i class="w-icon-category"></i>
                                <span>Browse Categories</span>
                            </a>

                            <div class="dropdown-box">
                                <ul class="menu vertical-menu category-menu">
                                    <li><a href="/view-products?main-category=NRI Recomends">NRI Recomends</a></li>
                                    <li><a href="/view-products?main-category=Pickles">Pickles</a>
                                        <ul class="megamenu">
                                            <li>
                                                <h4 class="menu-title">Veg Pickles</h4>
                                                <hr class="divider" />
                                                <ul>
                                                    <li><a href="/view-products?category=Veg Pickles">All Veg Pickles</a></li>
                                                </ul>
                                            </li>
                                            <li>
                                                <h4 class="menu-title">Non-Veg Pickles</h4>
                                                <hr class="divider" />
                                                <ul>
                                                    <li><a href="/view-products?sub-category=Mutton Pickles">Mutton Pickles</a></li>
                                                    <li><a href="/view-products?sub-category=Chicken Pickles">Chicken Pickles</a></li>
                                                    <li><a href="/view-products?sub-category=Fish Pickles">Fish Pickles</a></li>
                                                    <li><a href="/view-products?sub-category=Prawn Pickles">Prawn Pickles</a></li>
                                                    <li><a href="/view-products?sub-category=Mixed Non-Veg Pickles">Mixed Non-Veg Pickles</a></li>
                                                    <li><a href="/view-products?category=Non Veg Pickles">View All</a></li>
                                                </ul>
                                            </li>
                                            <li>
                                                <div class="banner-fixed menu-banner menu-banner2">
                                                    <figure>
                                                        <img src="assets/images/menu/banner-2.jpg" alt="Menu Banner"
                                                            width="235" height="300" />
                                                    </figure>
                                                    <div class="banner-content">
                                                        <div class="banner-price-info mb-1 ls-normal">Tired of your  
                                                            <strong
                                                                class="text-primary text-uppercase">Daily</strong>
                                                        </div>
                                                        <h3 class="banner-title ls-normal">Bland Meals?</h3>
                                                        <a href="/"
                                                            class="btn btn-dark btn-sm btn-link btn-slide-right btn-icon-right">
                                                            Shop Now<i class="w-icon-long-arrow-right"></i>
                                                        </a>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </li>
                                    <li><a href="/view-products?main-category=Sweets">Sweets</a>
                                        <ul class="megamenu">
                                            <li>
                                                <h4 class="menu-title">Our Sweets</h4>
                                                <hr class="divider" />
                                                <ul>
                                                    <li><a href="/view-products?category=Ghee Sweets">Ghee Sweets</a></li>
                                                    <li><a href="/view-products?category=Kaju Sweets">Kaju Sweets</a></li>
                                                    <li><a href="/view-products?category=Sugar-Free Sweets">Sugar-Free Sweets</a></li>
                                                    <li><a href="/view-products?category=Traditional Sweets">Traditional Sweets</a></li>
                                                    <li><a href="/view-products?category=Kalakand Sweets">Kalakand Sweets</a></li>
                                                    <li><a href="/view-products?main-category=Sweets">View All</a></li>
                                                </ul>
                                            </li>
                                            <li>
                                                <div class="banner-fixed menu-banner menu-banner2">
                                                    <figure>
                                                        <img src="assets/images/menu/banner-2.1.jpg" alt="Menu Banner"
                                                            width="235" height="300" />
                                                    </figure>
                                                </div>
                                            </li>
                                        </ul>
                                    </li>
                                    <li><a href="/view-products?main-category=Combos">Combos</a></li>
                                    <li><a href="/view-products?main-category=Namkeen">Namkeen</a></li>
                                    <li><a href="/view-products?main-category=Savouries">Savouries</a></li>
                                    <li><a href="/view-products?main-category=Podis">Podis</a></li>
                                    <li><a href="/view-products?main-category=Fryums">Fryums</a></li>
                                    <li><a href="/view-products?main-category=Instant Rice Mix">Instant Rice Mix</a></li>
                                    <li><a href="/view-products?main-category=Specials">Specials</a></li>
                                </ul>
                            </div>
                        </div>
                        <form method="get" action="#"
                            class="header-search hs-expanded hs-round d-none d-md-flex input-wrapper mr-4 ml-4">
                            <div class="select-box">
                                <select id="category" name="category">
                                    <option value="">All Categories</option>
                                    <option value="4">NRI Recomends</option>
                                    <option value="5">Pickles</option>
                                    <option value="6">Sweets</option>
                                    <option value="7">Namkeen</option>
                                    <option value="8">Savouries</option>
                                    <option value="9">Podis</option>
                                    <option value="10">Fryums</option>
                                    <option value="11">Instant Rice Mix</option>
                                    <option value="12">Specials</option>
                                </select>
                            </div>
                            <input type="text" class="form-control" name="search" id="search"
                                placeholder="Search in..." required />
                            <button class="btn btn-search" type="submit"><i class="w-icon-search"></i>
                            </button>
                        </form>
                    </div>
                    <div class="header-right pr-0 ml-4">
                        <a href="/" class="d-xl-show mr-6"><i class="w-icon-map-marker mr-1"></i>Track Order</a>
                       {userId > 0 ? <a onClick={logout}><i class="w-icon-user mr-1"></i>Logout</a> : <a href="/login"><i class="w-icon-user mr-1"></i>Login</a>}
                    </div>
                </div>
            </div>
        </div>
    </header>
    
    );
}

export default App;