import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Home from "./pages/home";
import Product from "./pages/product";
import NoPage from "./pages/404";
import Cart from "./pages/cart";
import Products from "./pages/viewProducts";
import AllProducts from "./pages/allProducts";
import Checkout from "./pages/checkout";
import Order from "./pages/order";
import Login from "./pages/login";
import Account from "./pages/account";



function App() {
    return (
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/product" element={<Product />} />
          <Route path="*" element={<NoPage />} /> 
          <Route path="/cart" element={<Cart />} /> 
          <Route path="/view-products" element={<Products />} /> 
          <Route path="/all-products" element={<AllProducts />} /> 
          <Route path="/checkout" element={<Checkout />} /> 
          <Route path="/order" element={<Order />} /> 
          <Route path="/login" element={<Login />} /> 
          <Route path="/my-account" element={<NoPage/>} /> 
        </Routes>
      </Router>
    );
  }
  
  export default App;